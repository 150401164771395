import { createStore } from 'vuex'

export default createStore({
  state: {
    cachedNews: [],
    cachedAlerts: [],
    cachedStocks: []
  },
  mutations: {
    setCacheNews(state, news) {
      state.cachedNews = news
    },
    setCacheAlerts(state, alerts) {
      state.cachedAlerts = alerts
    },
    setCacheStocks(state, stock) {
      state.cachedStock = stock
    }
  },
  actions: {
  },
  modules: {
  }
})