<template>
    <div class="m-4">
        <BaseNavbar />
    </div>
    <section class="section">
        <div class="container">
            <div class="column">
                <div v-if="news.length">
                    <p class="title" style="font-family: RoadRadio;">Новости</p>

                    <article class="message is-info is-light" v-for="notification in news">
                        <div class="message-header" style="font-family: RoadRadio;">
                            <p>{{ notification.news_name }}</p>
                        </div>
                        <div class="message-body" style="font-family: Littera Plain;">
                            {{ notification.news_body }}
                            <div class="is-flex is-flex-direction-column" style="font-family: Littera Plain;">
                                <div class="mt-4">
                                    <font-awesome-icon icon="phone" />
                                    Сall-Center: 78 888 28 88
                                </div>
                                <div class="mt-4">
                                    <font-awesome-icon icon="globe" />
                                    Оставьте заявку на подключение
                                </div>
                            </div>
                        </div>

                    </article>
                </div>

                <div v-if="alert.length">
                    <p class="title" style="font-family: RoadRadio;">Технические работы</p>

                    <article class="message is-info is-light" v-for="al in alert">
                        <div class="message-header" style="font-family: RoadRadio;">
                            <p>{{ al.alert_name }}</p>
                        </div>
                        <div class="message-body" style="font-family: Littera Plain;">
                            {{ al.alert_body }}
                       
                            <div class="is-flex is-flex-direction-column" style="font-family: Littera Plain;">
                                <div class="mt-4">
                                    <font-awesome-icon icon="phone" />
                                    Сall-Center: 78 888 28 88
                                </div>
                                <div class="mt-4">
                                    <font-awesome-icon icon="globe" />
                                    Оставьте заявку на подключение
                                </div>
                            </div>
                        </div>
                    </article>
                </div>

                <div v-if="stock.length > 0">
                    <p class="title" style="font-family: RoadRadio;">Акции</p>

                    <article class="message is-info is-light" v-for="st in stock">
                        <div class="message-header" style="font-family: RoadRadio;">
                            <p>{{ st.stock_name }}</p>
                        </div>
                        <div class="message-body" style="font-family: Littera Plain;">
                            {{ st.stock_body }}
                            <div class="is-flex is-flex-direction-column" style="font-family: Littera Plain;">
                                <div class="mt-4">
                                    <font-awesome-icon icon="phone" />
                                    Сall-Center: 78 888 28 88
                                </div>
                                <div class="mt-4">
                                    <font-awesome-icon icon="globe" />
                                    Оставьте заявку на подключение
                                </div>
                            </div>
                        </div>

                    </article>


                </div>

            </div>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Оставить заявку</p>
                    <p class="subtitle is-hidden-mobile" style="font-family: Littera Plain;">Ваша улыблка наш успех!</p>
                </div>
                <div class="column">
                    <ConnectForm @clientRequest="clientRequest" />
                </div>
            </div>
        </div>
    </section>
    <UTFooter />
</template>

<script>
import ConnectForm from "@/components/Base/ConnectForm.vue";
import News from "@/components/Home/News.vue";
import BaseNavbar from "@/components/Base/BaseNavbar.vue";
import UTFooter from "@/components/Base/UTFooter.vue";
import axios from "axios";
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';

export default {
    name: "NewsDetailView",
    components: {
        BaseNavbar,
        News,
        UTFooter,
        ConnectForm,
        FontAwesomeIcon
    },
    data() {
        return {
            news: [],
            alert: [],
            stock: [],
        }
    },
    methods: {
        clientRequest(formData) {
            axios
                .post('/api/v1/connect-message/', formData)
                .then(response => {
                    console.log(response);
                })
        },
        getNotifications(type) {
            let notification = this.$route.params.type
            let notificationId = this.$route.params.id

            if (notification === 'news') {
                axios
                    .get(`/api/v1/get-news/detail/${notificationId}`)
                    .then(response => {
                        console.log(response);
                        this.news = []
                        this.news.push(response.data)
                    })
            }
            else if (notification === 'alert') {
                axios
                    .get(`/api/v1/get-alert/detail/${notificationId}`)
                    .then(response => {
                        console.log(response);
                        this.alert = []
                        this.alert.push(response.data)
                    })
            }
            else if (notification === 'stock') {
                axios
                    .get(`/api/v1/get-stock/detail/${notificationId}`)
                    .then(response => {
                        console.log(response);
                        this.stock = []
                        this.stock.push(response.data)
                    })
            }
        },
    },
    mounted() {
        this.getNotifications()
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
    @media(max-width: 320px) {
        .message-body {
            font-size: 14px;
        }

        .title {
            font-size: 25px;
        }
    }
</style>