<template>
    <div class="m-4">
        <BaseNavbar />
    </div>
    <hr>
    <div class="container">
        <div class="content">
            <h4 class="has-text-centered">Публичная оферта на оказание услуг телекоммуникаций</h4>
            <p><strong>г. Ташкент</strong></p>
            Настоящая Публичная оферта является официальным предложением <i class="is-underlined">ООО «Independent Telecom Innovations»</i>
            (ТМ URBAN TELECOM), оказывающее услуги телекоммуникации на основании Лицензии <i class="is-underlined">АА 0007319 </i>, именуемое в дальнейшем
            ОПЕРАТОР, в лице Директора <i class="is-underlined">Харратова Отабека Маратовича</i>, действующего на основании Устава, заключить договор с
            субъектом гражданских правоотношений, именуемый в дальнейшем АБОНЕНТ на оказание услуг телекоммуникаций (далее -
            Услуги).
            Публичная оферта вступает в силу с момента ее размещения на сайте Оператора в сети Интернет, по адресу:
            <i class="has-text-link">www.urbantelecom.uz </i>, и действует до размещения на сайте официального извещения о её отзыве.
            Оператор оставляет за собой право внести изменения в условия Публичной оферты в любой момент по своему
            усмотрению, в порядке, установленном настоящей Публичной офертой. Условия настоящей публичной оферты являются
            едиными для всех обратившихся к Оператору субъектов гражданских правоотношений.
            <h4 class="has-text-centered m-2">Термины и определения</h4>
            Для целей настоящей Публичной оферты применяются следующие основные термины, определения и сокращения:
            Абонент – субъект гражданских правоотношений (физическое лицо, юридическое лицо, некоммерческая организация,
            публично-правовое образование и т.д.) заключившее договор на оказание услуг телекоммуникаций с Оператором на
            условиях настоящей Публичной оферты.
            <br>
            <strong>Оператор</strong> - ООО «Independent Telecom Innovations», предлагающее Абонентам комплексный набор
            услуг
            телекоммуникаций, владеющее сетью средств телекоммуникаций, обеспечивающее ее функциональность, развитие.
            <br>
            <strong>Стороны</strong> – Оператор и Абонент совместно, а раздельно Сторона.
            <br>
            <strong>Офис Оператора</strong> – помещения, здания в г. Ташкенте и регионах Республики Узбекистан, в которых
            принимаются и
            обслуживаются Абоненты, хранится и обрабатывается информация об Абонентах и вся документация, связанная с
            деятельностью Оператора. Информация о месте нахождения, режиме работы офисов Оператора размещена на сайте
            Оператора и актуализируется (изменение места расположения, почтового адреса и т.п.) в установленном порядке.
            <br>
            <strong>Пункт продаж</strong> – (Офис Оператора) место заключения в установленном порядке Абонентского
            договора/Регистрационного
            бланка на оказание услуг телекоммуникаций с Абонентом на условиях настоящей Публичной оферты.
            <br>
            <strong> Платежный агент</strong> – юридическое лицо или индивидуальный предприниматель, осуществляющий
            деятельность по приему
            платежей от Абонентов с использованием стационарных или мобильных устройств, на основании заключенных договоров
            с Оператором на осуществление платежей.
            <br>
            <strong>Техническая возможность принятия услуг телекоммуникаций</strong>- наличие у Абонента соответствующих
            технических средств
            и условий, необходимых для пользования Услугами телекоммуникаций в соответствии с условиями настоящего Договора.
            <br>
            <strong>Техническая возможность предоставления Услуг телекоммуникаций</strong> - наличие функционирующих средств
            телекоммуникаций
            в зоне обслуживания Оператора, необходимых для оказания Абоненту Услуг телекоммуникаций.
            <br>
            <strong>Абонентское устройство/Пользовательское оборудование</strong> - оконечное оборудование, предоставляющее
            Абоненту
            возможность пользоваться услугами телекоммуникаций. Абонентское устройство/Пользовательское оборудование должно
            соответствовать техническим требованиям, предъявляемым к данному виду оборудования в соответствии c действующими
            в Республике Узбекистан стандартами.
            <br>
            <strong>Зона обслуживания</strong> - территория, на которой Оператор имеет право на основании советующих
            лицензий оказывать
            Услуги и обладает техническими возможностями для обеспечения качества услуг, соответствующих условиям,
            установленным нормативно-правовыми актами Республики Узбекистан
            <br>
            <strong>Правила оказания услуг/Правила</strong> – означает действующий Государственный стандарт Республики
            Узбекистан «Показатели
            и нормы качества услуг передачи данных» № O‘z DSt 3205:2017. Ссылка на стандарт указан на сайте Оператора.
            <br>
            <strong>Прейскурант</strong> – утвержденный документ Оператора, содержащий перечень и ценовые условия Услуг
            телекоммуникаций.
            <br>
            <strong>Тарифы</strong> — установленная Оператором цена за единицу измерения Услуг телекоммуникаций,
            оплачиваемая Абонентом при
            подключении к сети Оператора.
            <br>
            <strong>Регистрационный бланк</strong> – документ, фиксирующий факт присоединения Абонента к условиям настоящей
            Публичной оферты.
            Содержит сведения об Абоненте, виды Услуг телекоммуникаций, которые Оператор обязуется оказать Абоненту и
            необходимых для их оказания элементов (абонентский номер, Тарифный план и т.п.) и другие существенные условия.
            Регистрационный бланк оформляется по каждому виду Услуг телекоммуникаций с Абонентами физическими лицами.
            <br>
            <strong>Аутентификационные данные</strong> — (применяется если они требуются) совокупность индивидуальных
            учетных данных
            Абонента, формируемых Оператором на основании Договора заключенного на условиях настоящей Публичной оферты:
            <br>
            а) Имя пользователя (login) — основной индивидуальный идентификационный параметр Абонента в системе Оператора.
            Присваивается Абоненту при заключении Договора и первичной регистрации Абонента. Не может быть изменен в рамках
            действующего Договора.
            <br>
            б) Пароль (password) — средство защиты от несанкционированного доступа к индивидуальным учетным данным и другой
            закрытой информации Абонента. Первичный пароль формируется и присваивается Оператором. В дальнейшем пароль может
            быть изменен Абонентом. Имя пользователя и пароль используются Абонентом для установки удаленного соединения и
            авторизации на сервере доступа Оператора, а также для входа в персональный кабинет.
            <br>
            <strong>Авторизация</strong> — процесс анализа на сервере Оператора введенных Абонентом Аутентификационных
            данных, по результатам
            которого определяется наличие у Абонента права получить услугу или войти в персональный кабинет.
            <br>
            <strong>Персональный кабинет </strong> — специализированная, защищенная от несанкционированного доступа к
            Аутентификационным
            данным Абонента, веб-страница на сайте Оператора. Содержит Аутентификационные данные, статистическую информацию
            об объемах полученных Услуг, текущем состоянии Лицевого счета Абонента и другие данные. Информация, отображаемая
            в персональном кабинете, является справочной и может отличаться от информации в АСР Оператора. Показания в
            персональном кабинете при текущей, незавершенной сессии подключения, могут отличатся от показаний после
            завершения сессии и считаются достоверными только после фиксирования завершенности сессии в параметрах
            персонального кабинета. В персональном кабинете Абонент может осуществлять подписку на конкретные услуги, отказ
            от них, изменение или восстановление Пароля, другие доступные возможности, предоставляемые Оператором. Функции
            персонального кабинета могут быть изменены в одностороннем порядке Оператором. Постоянная ссылка на веб-страницу
            персонального кабинета указана на сайте Оператора.
            <br>
            <strong>Лицевой (электронный) счет</strong> – электронный файл в автоматизированной системе расчетов Оператора,
            содержащий
            выраженную в денежном эквиваленте информацию об объеме прав Абонента на получение услуг на данную сумму, а также
            информацию о поступлении и расходовании Авансового платежа.
            Уровень ограничения – минимальный размер денежных средств на лицевом счете (балансе) Абонента, при котором
            Оператор имеет право ограничить объем оказываемых Абоненту Услуг, либо приостановить оказание Услуг.
            <br>
            <strong>Авансовый платеж</strong> – сумма денежных средств, подлежащая уплате в полном объеме (100%) Абонентом
            за пользование
            телекоммуникационными услугами в предстоящем расчетном периоде (предварительная оплата) на расчетный счет
            Оператора, с указанием логина Абонента и номера Договора для субъектов предпринимательства. Авансовый платеж
            формируется из стоимости абонентской платы согласно выбранному тарифному плану, стоимости выбранных платных
            Услуг телекоммуникаций. Авансовый платеж производится доступными способами наличных и безналичных расчетов,
            установленных в Республике Узбекистан.
            <br>
            <strong>Абонентская плата</strong> – безоговорочная ежемесячная фиксированная плата за Услуги, предоставляемые
            Оператором
            Абоненту, оплачиваемая вне зависимости от объема потребленных Услуг.
            <br>
            <strong>Баланс лицевого счета</strong> — разность между двумя суммами денежных средств в определенный момент
            времени на Лицевом
            счете Абонента. Первая сумма состоит из денежных средств, внесенных на Лицевой счет, вторая сумма состоит из
            денежных средств, списанных Оператором с Лицевого счета Абонента за оказанные Услуги телекоммуникаций в
            установленный настоящим Договором срок.
            <br>
            <strong>АСР</strong> – автоматизированная система расчетов/биллинговая система (аппаратно-программный комплекс),
            предназначенная
            для автоматизации расчетов между Оператором и Абонентом, а также для учета и обработки информации об оказанных
            Услугах телекоммуникаций.
            <br>
            <strong>Сеть Оператора</strong> – совокупность средств телекоммуникаций, с помощью которых Абоненту оказываются
            Услуги
            телекоммуникаций. Сеть Оператора включает: сеть передачи данных - сеть телекоммуникаций, предназначенная для
            оказания услуг передачи данных всем юридическим и физическим лицам на территории Республики Узбекистан; сеть
            <br>
            <strong>Интернет-связи</strong> - компьютерная сеть, состоящая из глобального сообщества компьютерных сетей,
            использующих единое
            адресное пространство и протокол ТСР/IP для обеспечения передачи и обмена данными.
            <br>
            <strong>Предоставление доступа к сети Оператора</strong> – действия Оператора по обработке данных об Абоненте и
            ввода их в
            аппаратно-программный комплекс Оператора. Возможность доступа к сети Оператора возникает у Абонента после
            внесения авансового платежа.
            <br>
            <strong>Добросовестное пользование</strong> – осуществление Абонентом прав и обязанностей при пользовании
            Услугами
            телекоммуникаций в строгом соответствии с действующим законодательством Республики Узбекистан, Правилами и
            условиями к выбранному виду услуги телекоммуникаций и условиями настоящего Договора, где исключается
            использование Услуг телекоммуникаций в противоправных целях, а равно совершение действий, наносящих вред
            Оператору, и/или третьим лицам, распространение и не способствование распространению программного
            обеспечения/информации со своего Абонентского устройства/Пользовательского оборудования, не фальсифицирование
            IP-адресов, абонентских номеров, не совершение действий, препятствующих нормальному функционированию сети
            Оператора, оборудования, программного обеспечения Оператора и/или третьих лиц, и не использование
            непосредственно Услуг телекоммуникаций в коммерческих целях, в шлюзах для доступа в сети фиксированной связи,
            Интернет телефонии и т.п.
            <br>
            <strong>Зона ответственности Оператора</strong> — технические средства и каналы телекоммуникаций, находящиеся во
            владении и под
            управлением Оператора. Услуги оказываются до зоны ответственности Оператора. Все вопросы стыковки с услугами
            Оператора вне зоны его ответственности решает Абонент в согласовании с Оператором. Невозможность оказания
            Оператором на должном уровне качества, вне зоны его ответственности являться основанием к расторжению Договора.
            До заключения Договора с Оператором Абонент обязан, убедится в доступности каналов связи от Оператора до своей
            точки подключения. При отсутствии доступных каналов связи вопросы об организации таких каналов связи решаются
            вне рамок и условий настоящего Договора.
            <br>
            <strong>Крупная авария</strong> – авария, приведшая к приостановлению действия сети Оператора и оказанию Услуг.
            <br>
            <strong>Фрод</strong> – вид мошенничества в области информационных технологий, в частности несанкционированные
            действия и
            неправомочное пользование ресурсами и Услугами телекоммуникаций Оператора.
            <br>
            <strong>Сайт Оператора </strong> – информационный ресурс Оператора, содержащий официальную информацию о
            деятельности,
            предоставляемых услугах телекоммуникаций Оператора, размещенный в сети Интернет по электронному адресу:
            <i class="has-text-link">www.urbantelecom.uz</i>
            <br>
            <strong>Публичная оферта</strong> - предложение Оператора о заключении Договора, в котором изложены существенные
            условия
            договора, который заключается между Абонентом и Оператором, посредством подписания его Абонентом на сайте
            Оператора, расположенного по адресу <i class="has-text-link">www.urbantelecom.uz/public-offer</i>
            <br>
            <strong> Акцепт оферты </strong> - полное и безоговорочное принятие условий Договора публичной оферты путем
            подписания его
            Абонентом.
            <h4 class="has-text-centered m-2">I. Предмет договора</h4>
            <strong>1.1.</strong> Оператор обязуется оказывать Абоненту Услуги телекоммуникаций, а Абонент обязуется
            оплачивать оказанные
            Оператором Услуги телекоммуникаций на условиях и в порядке, установленных настоящим Договором.
            <br>
            <strong>1.2.</strong> Стороны обеспечивают эксплуатацию и техническое обслуживание соединения, а также
            взаимодействие, при этом
            Оператор предоставляет Абоненту услугу, а Абонент принимает услугу и производит их оплату в соответствии с
            условиями и порядком, указанным в регистрационном бланке к настоящему Договору.
            <br>
            <strong>1.3.</strong> Настоящий Договор и приложения к нему разработаны на основании Правил оказания
            телекоммуникационных услуг,
            утвержденных приказом за № 208-МҲ от 30.06.2020г. Министром по развитию информационных технологий и коммуникаций
            Республики Узбекистан (МЮ № 3275 от 30.06.2020г.).
            <h4 class="has-text-centered m-2">II. Порядок заключения договора и условия оказания услуг</h4>
            <strong>2.1.</strong> Для заключения Договора Абонент предоставляет Оператору перечень документов, указанных на
            сайте Оператора.
            При этом Абонент дает согласие Оператору или его Платежным агентам на обработку данных Абонента, в том числе
            персональных данных, в соответствии с требованиями действующего законодательства Республики Узбекистан.
            <br>
            <strong>2.2.</strong> Договор на оказание Услуг телекоммуникаций между Абонентом и Оператором заключается на
            сайте Оператора,
            либо иным удобным для Оператора способом, не противоречащим законодательству Республики Узбекистан.
            <br>
            <strong>2.3.</strong> При подписании Договора Стороны должны установить Технические возможности принятия и
            предоставления услуг
            телекоммуникаций.
            В случае отсутствия Технической возможности предоставления услуг телекоммуникаций, Договор приобретает силу
            обращения Абонента на оказание Услуг телекоммуникаций до момента появления Технической возможности
            предоставления услуг телекоммуникаций, но не более, чем на 30 (тридцать) календарных дней, с момента подписания
            Договора.
            По истечении данного срока, при отсутствии технической возможности предоставления услуг Договор утрачивает силу.
            <br>
            <strong>2.4.</strong> Началом оказания услуг телекоммуникаций является внесение Абонентом Первоначального
            авансового платежа.
            Невнесение Первоначального авансового платежа Абонентом при наличии технической возможности оказания услуг
            телекоммуникаций в течение 7 (семи) календарных дней с момента подписания Договора Сторонами, означает отказ
            Абонента от Договора. Если Первоначальный Авансовый платеж не был внесен в полном объеме в течение срока,
            установленного настоящим пунктом, внесенная сумма подлежит возврату в порядке, предусмотренном настоящим
            Договором.
            <br>
            <strong>2.5.</strong> Оператор самостоятельно определяет перечень оказываемых Услуг с учетом Технических
            возможностей
            предоставления услуг телекоммуникаций.
            <br>
            <strong>2.6.</strong> Порядок оказания Услуг телекоммуникаций определяется в соответствующих Правилах по видам
            услуг, оказываемых
            Оператором, являющихся неотъемлемой частью настоящего Договора.
            <br>
            <strong>2.7.</strong> Для получения Услуг телекоммуникаций Абонент должен иметь Техническую возможность принятия
            услуг
            телекоммуникаций. При этом Оператор не несет перед Абонентом обязательств по предоставлению (оказанию услуг по
            настройке) технических средств и условий для принятия Услуг телекоммуникаций.
            <br>
            <strong>2.8.</strong> При подписании Абонентом договора, либо выполнением иных действий подтверждающих принятие
            (акцепта) условий
            настоящей Публичной оферты, Абонент дает согласие на использование помещений в том числе (помещений общего
            пользования), опорных конструкций дома, механического, электрического и иного оборудования за пределами или в
            пределах помещения (офиса), а также строения, дома, которые предназначены для обеспечения потребностей в
            Услугах, оказываемых Оператором.
            <br>
            <strong>2.9.</strong> Абонент получает возможность пользоваться Услугами после поступления авансового платежа на
            расчетный счет
            Оператора (начало оказания услуг).
            <br>
            <strong>2.10.</strong> Идентификация Абонента и доступ к Услугам и Персональному кабинету осуществляется с
            использованием
            персональных Аутентификационных данных Абонента.
            <br>
            <strong>2.11.</strong> Предоставляемые Услуги телекоммуникаций подразделяются на:
            <br>
            - базовые услуги телекоммуникаций – услуги, оказываемые без взимания дополнительной платы. Перечень базовых
            услуг телекоммуникаций определяется в установленном порядке;
            <br>
            - дополнительные услуги телекоммуникаций – услуги, за которые Оператором дополнительно взимается плата по
            Прейскуранту.
            <br>
            <strong>2.12.</strong> При предоставлении доступа к Услугам телекоммуникаций Абоненту предоставляются базовые
            услуги
            телекоммуникаций. Дополнительные услуги телекоммуникаций оказываются как совместно с базовыми услугами
            телекоммуникаций, так по отдельности в соответствии с выбранным Тарифом/Тарифным планом и соответствующими
            Правилами к выбранной Услуге телекоммуникаций.
            <br>
            <strong>2.13.</strong> Доступ Абонента к базовым или дополнительным услугам телекоммуникаций связанных с
            базовыми услугами
            телекоммуникаций, после выработки лимита, установленного выбранным Абонентом Тарифным планом, предоставляется
            при положительном Балансе лицевого счета.
            <br>
            <strong>2.14.</strong> При нулевом или отрицательном балансе лицевого счета Оператор блокирует доступ к базовым
            и дополнительным
            услугам телекоммуникаций до внесения Абонентом Авансового платежа и пополнения средств на счете.
            <br>
            <strong>2.15.</strong> Услуги телекоммуникаций оказываются Оператором Абоненту 24 часа в сутки, ежедневно, без
            перерывов, за
            исключением проведения необходимых профилактических и ремонтных работ.
            Не является перерывом в предоставлении и/или нарушением установленных сроков оказания Услуг телекоммуникаций
            проведение профилактических и ремонтных работ, а также во время Крупных аварий и устранения их последствий, в
            том числе неполадки в Абонентском устройстве/Пользовательском оборудовании.
            <h4 class="has-text-centered m-2">III. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h4>
            <div class="is-underlined">
                <strong>3.1. Оператор обязуется:</strong>
            </div>
            <strong>3.1.1.</strong> Принимать все необходимые меры для обеспечения качества услуг в пределах зоны
            ответственности Оператора и
            предоставить гарантии на выполненные работы по подключению, включая материалы и оборудование Оператора.
            <br>
            <strong>3.1.2.</strong> Предоставлять Абоненту достоверную информацию об оказываемых Услугах.
            <br>
            <strong>3.1.3.</strong> Оказывать Абоненту услуги в соответствие с действующим законодательством Республики
            Узбекистан,
            лицензиями и условиями настоящего договора, при соблюдении Абонентом условий настоящего Договора.
            <br>
            <strong>3.1.4.</strong> Обеспечить Абонента технической поддержкой, необходимой для нормальной эксплуатации
            оборудования
            Оператора и использования потребляемых услуг.
            <br>
            <strong>3.1.5.</strong> Обеспечивать возможность ежедневного, круглосуточного получения Абонентом услуг, без
            перерывов, за
            исключением перерывов для проведения ремонтных и планово-профилактических работ.
            <br>
            <strong>3.1.6.</strong> Уведомлять Абонента о проведении плановых технологических работ, которые могут привести
            к нарушению
            нормального режима предоставления услуг, за 24 часа до начала проведения работ и в течение 1 часа в случае
            аварийных ситуаций посредством электронной почты или сообщения на сайте Оператора.
            <br>
            <strong>3.1.7.</strong> Заблаговременно в письменном виде посредством электронной почты или сообщения на сайте
            Оператора или
            отправлением уведомление в виде SMS на номер телефона предупреждать Абонента о приостановлении соединения, если
            Абонент нарушил условия настоящего Договора или по причинам, связанным с чрезвычайными ситуациями.
            <br>
            <strong>3.1.8.</strong> Принимать к рассмотрению рекламации со стороны Абонента в установленном порядке.
            <br>
            <strong>3.1.9.</strong> По заявке Абонента устранять повреждения и неполадки в работе сети, в сроки,
            установленные в Правилах.
            <br>
            <strong>3.1.10.</strong> Вести лицевой счет Абонента с целью учета платежей и объема оказанных услуг, а также в
            целях соблюдения
            требований законодательства.
            <br>
            <strong>3.1.11.</strong> Производить своевременный пересчет платежей Абонента в случае перерывов оказания услуг
            или при их
            ненадлежащем качестве, происшедших по вине Оператора и оформленных соответствующим Актом.
            <br>
            <strong>3.1.12.</strong> Оповещать Абонента об изменениях Тарифов за 10 дней до даты введения изменений
            посредством сообщения на
            сайте Оператора или отправлением уведомление на электронную почту Абонента или в виде SMS на номер телефона.
            <br>
            <strong>3.1.13.</strong> По письменному обращению Абонента представить детальный отчет по объёму использованного
            трафика работы в
            сети Интернет, посредством электронной почты (включая ежедневную статистику).
            <br>
            <strong>3.1.14.</strong> Производить подключение Абонента к Сети Оператора, отключенного за неоплату Услуг и/или
            невнесение
            Абонентской платы, после ликвидации (погашения) им задолженности по оплате потребленных Услуг и внесения
            предоплаты в размере, согласно выбранному Абонентом Тарифу/Тарифному плану.
            <br>
            <strong>3.1.15.</strong> Принимать необходимые организационные меры для защиты персональных данных Абонента и
            соблюдать иные
            установленные законодательством требования к обеспечению конфиденциальности сведений об Абоненте.
            <br>
            <strong>3.1.16.</strong> Публиковать официальные сообщения, связанные с обслуживанием Абонентов, изменением
            условий
            предоставления Услуг и их стоимости, изменением банковских реквизитов и другой необходимой информации на своем
            сайте.
            <br>
            <strong>3.1.17.</strong> Сообщать в соответствующие правоохранительные органы о случаях фрода и нарушения
            обязательств Абонента,
            указанных в настоящем Договоре.
            <br>
            <div class="mt-1 is-underlined"><strong>3.2. Абонент обязуется:</strong></div>
            <strong>3.2.1.</strong> Соблюдать все условия, предусмотренные настоящим Договором.
            <br>
            <strong>3.2.2.</strong> Ознакомится с перечнем актуальных Услуг, тарифными планами, тарифами на Услуги и прочей
            сопроводительной
            информацией, до начала пользования Услугами.
            <br>
            <strong>3.2.3.</strong> Периодически (не реже одного раза в течение 7 дней) проверять наличие изменений на
            сайте информации по
            предоставляемым Услугам.
            <br>
            <strong>3.2.4.</strong> Своевременно и в полном объеме оплачивать Услуги в соответствии с действующим порядком
            расчетов,
            Тарифом/Тарифным планом и перечнем предоставляемых Услуг, согласно условиям настоящего Договора.
            <br>
            <strong>3.2.5.</strong> Поддерживать положительный баланс Лицевого счета, своевременно производить необходимые
            Авансовые
            платежи/Абонентскую плату на расчётный счет Оператора.
            <br>
            <strong>3.2.6.</strong> Выполнять и соблюдать требования нормативно-правовых актов РУз, касающихся сети передачи
            данных и
            телекоммуникационных услуг.
            <br>
            <strong>3.2.7.</strong> Не допускать распространения информации, содержащей призывы к насильственному изменению
            конституционного
            строя, пропаганду войны, насилия и порнографии, разжигание религиозной и национальной розни, ущемление чести и
            достоинства человека и другой информации, запрещенной к распространению действующим законодательством РУз.
            <br>
            <strong>3.2.8.</strong> Строго выполнять требования по обеспечению информационной безопасности, оказывать
            содействие Оператору в
            решении вопросов информационной безопасности.
            <br>
            <strong>3.2.9.</strong> Не допускать подключения к сети интернет, не подтвержденных к использованию в Республике
            Узбекистан и не
            соответствующих техническим требованиям.
            <br>
            <strong>3.2.10.</strong> Не использовать Услуги в противоправных целях, а равно не совершать действий, наносящих
            вред Оператору
            и/или взаимосвязанным с ним третьим лицам, в том числе не распространять и не способствовать распространению
            программного обеспечения со своего Абонентского устройства/Пользовательского оборудования, не фальсифицировать
            IP-адреса, не совершать действий, препятствующих нормальному функционированию сети Оператора, оборудования,
            программного обеспечения Оператора и/или взаимосвязанных с ним третьих лиц, не совершать неправомерных и /или
            противоправных действий в области информационных технологий, в частности, несанкционированные действия и
            неправомерное пользование ресурсами и услугами в сети Оператора.
            <br>
            <strong>3.2.11.</strong> Использовать Абонентское устройство/Пользовательской оборудование, которое имеет
            сертификат на
            соответствие согласно установленным стандартам в Республике Узбекистан.
            <br>
            <strong>3.2.12.</strong> Использовать Абонентское устройство/Пользовательское оборудование в соответствии с
            инструкцией
            производителя по его эксплуатации, с учетом особых распоряжений и правил, действующих на определённой
            территории и ограничений в случаях возможного возникновения помех или опасной ситуации. Оператор не обязан
            информировать Абонента об указанных ограничениях и требованиях.
            <br>
            <strong>3.2.13.</strong> Дать свое согласия на получение рекламных и информационных рассылок от Олператора.
            <br>
            <strong>3.2.14.</strong> Не передавать свои сетевые реквизиты и не предоставлять услуги Оператора третьим лицам,
            если это не
            оговорено отдельными соглашениями с Оператором.
            <br>
            <strong>3.2.15.</strong> В случае обнаружения утечки информации или несанкционированного использования
            реквизитов уведомить
            Оператора в течение суток с момента обнаружения.
            <br>
            <strong>3.2.16.</strong> Использовать переданное оборудование и предоставляемые услуги в соответствии с
            назначением и
            техническими условиями эксплуатации, поддерживать его в исправном состоянии, обеспечить его сохранность и
            возврат Оператору. В случае обнаружения повреждения в течение 24 часов известить об этом Оператора.
            <br>
            <strong>3.2.17.</strong> При оказании лицензируемых услуг интернет-кафе, интернет-клуб и других услуг, связанных
            с выходом на
            международные сети, самостоятельно оформить необходимые разрешения и лицензии и предоставить Оператору копии
            соответствующих разрешений и Лицензий.
            <br>
            <strong>3.2.18.</strong> Не использовать предоставленное ему право работы в сети передачи данных для
            несанкционированного доступа
            и порчи оборудования других пользователей сети, не допускать случаев подключения к подаваемому сигналу
            телеприемников других владельцев.
            <br>
            <strong>3.2.19.</strong> Содержать в исправности абонентскую линию и абонентские устройства в своем помещении.
            Не использовать Абонентское устройство/Пользовательское оборудование в противоправных целях, нарушающих
            требования действующего законодательства Республики Узбекистан, права и интересы Оператора, в том числе
            приведших к имиджевому, финансовому и иному ущербу. Абонент признает, что Услуги, оказываемые Оператором
            являются лицензионным видом деятельности и за нарушение порядка в их предоставлении, в том числе за установку
            шлюзов для доступа в сети фиксированной связи, Интернет телефонии и других мероприятий, приводящих к нарушению
            работоспособности Абонентского устройства/Пользовательского оборудования и/или устройств связи, приведших к
            нанесению ущерба Оператору, Абонент несет установленную ответственность, а так же полностью возмещает
            причинённый Оператору ущерб. В случае утраты, неисправности либо повреждения Абонентского
            устройства/Пользовательского оборудования, произошедших по вине Абонента, Абонент обязан возместить убытки
            согласно принятым обязательствам.
            <br>
            <strong>3.2.20.</strong> Предоставлять Оператору персональные данные, подтвержденные документально, а также
            точный почтовый адрес
            для доставки корреспонденции, банковские реквизиты, почтовый адрес и адрес временной прописки, а также другие
            сведения, используемые в целях исполнения Договора, в объеме, в котором Оператору необходимы такие данные при
            заключении Договора.
            В случае изменения указанных данных представить Оператору новые данные в письменной форме в течение 10 (десять)
            календарных дней с даты вступления в силу таких изменений. (В случае неисполнения Абонентом указанной
            обязанности, исполнение обязательств по настоящему Договору Оператором по будут считаться надлежащим.)
            Услуги с использованием новых данных о почтовом адресе Абонента оказываются Оператором с первого числа месяца
            следующего за месяцем, в котором Оператором получена информация о таких данных.
            <br>
            <strong>3.2.21.</strong> Предоставить место для размещения и установки оборудования Оператора, в рамках
            исполнения Оператором
            своих обязательств.
            <br>
            <strong>3.2.22.</strong> Обеспечить в процессе подключения и оказания услуг беспрепятственный доступ работникам
            Оператора к
            оборудованию, помещениям и местам Абонента, где будет установлено оборудование.
            <br>
            <strong>3.2.23.</strong> Абонент принимает на себя всю ответственность за использование Услуг. Не допускается
            использование
            Услуг, предоставленных Оператором с нарушением действующего законодательства Республики Узбекистан.
            <br>
            <strong>3.2.24.</strong> Своевременно до 10 числа каждого месяца (для Абонентов кроме физических лиц) забирать
            счета и
            счета-фактуры в офисе Оператора и перечислять на расчётный счет Оператора суммы по выставляемым счетам. Отказ
            Абонента от получения, в том числе в случае доставки счетов Оператором по адресу доставки, или неполучение по
            другим каким-либо причинам счетов, не освобождает Абонента от своевременной оплаты услуг Оператора.
            <br>
            <strong>3.2.25.</strong> Строго выполнять требования по обеспечению информационной безопасности и защите.
            <div class="mt-1 is-underlined">
                <strong>3.3. Оператор имеет право:</strong>
            </div>
            <strong>3.3.1.</strong> Отказать в предоставлении услуг по следующим причинам:
            - предоставление Услуги может создать угрозу безопасности и обороноспособности государства, здоровью и
            безопасности людей;
            <br>
            - предоставление Услуги стало невозможно ввиду каких-либо физических, топографических или иных естественных
            препятствий;
            <br>
            - при наличии у Оператора сведений о задолженности потенциального Абонента за ранее оказанные Оператором Услуги
            телекоммуникаций;
            <br>
            - при наличии у Оператора сведений о Абонентском устройстве/Пользовательском оборудовании как об утраченном
            другим Абонентом или не сертифицированном в соответствии с законодательством Республики Узбекистан.
            <br>
            - Абонент использует или намерен использовать Услугу для каких-либо незаконных целей, или же получает Услугу не
            законным способом, эксплуатирует предоставленное оборудование с нарушением правил технической эксплуатации.
            <br>
            <strong>3.3.2.</strong> В одностороннем порядке приостанавливать предоставление услуг в случаях:
            - невнесения/несвоевременного внесения Авансового платежа/Абонентской платы Абонентом за Услуги
            телекоммуникаций.
            <br>
            - нарушения Абонентом нормативно-правовых актов РУз, устанавливающих порядок и правила пользования услуг связи;
            <br>
            - проведения плановых и/или внеплановых работ в сети передачи данных с уведомлением Абонента;
            <br>
            - немедленно, если продолжение работы Абонента может нанести ущерб техническим средствам или программному
            обеспечению Оператора;
            <br>
            - попыток получения несанкционированного доступа к программам, аппаратным и информационным ресурсам сети
            передачи данных с использованием реквизитов Оператора или Абонента;
            <br>
            - массовой рассылки информации, невостребованной пользователями сети (СПАМ);
            <br>
            - использования Абонентом Абонентского устройства/Пользовательского оборудования для оказания платных услуг;
            <br>
            - в случае нарушения Абонентом условий настоящего Договора и в иных случаях, предусмотренных действующим
            законодательством Республики Узбекистан, соответствующими правилами оказания Услуг телекоммуникаций. Повторное
            Предоставление доступа Абонента к сети Оператора производится по тарифам Оператора после полного устранения
            нарушений, сохранение ранее установленного Абонентом тарифа/Тарифного плана при этом не гарантируется;
            <br>
            - по другим причинам предусмотренных действующим законодательством РУз.
            <br>
            <strong>3.3.3.</strong> Приостанавливать, блокировать или запрещать использование программного обеспечения
            Абонента в случае,
            если:
            <br>
            - эксплуатация такого программного обеспечения приводит или может привести к аварийным ситуациям, нарушению
            системы безопасности или условий Договора;
            <br>
            - использование Услуг хостинга осуществляется в качестве прокси-сервера, сервера виртуальной частной сети (VPN),
            а также установка на хостинг-аккаунте программного обеспечения, связанного с прокси- серверами и VPN.
            <br>
            <strong>3.3.4.</strong> Предпринимать соответствующие организационные и технические действия, направленные на
            обеспечение защиты
            информационных ресурсов, недопущение распространения информации, содержащей призывы к насильственному свержению
            конституционного строя, пропаганду войны, насилия и порнографии, разжигание религиозной и национальной розни,
            ущемление чести и достоинства человека и другой информации, запрещенной к распространению действующим
            законодательством.
            <br>
            <strong>3.3.5.</strong> В одностороннем порядке изменять тарифы в порядке, установленном настоящим договором.
            <br>
            <strong>3.3.6.</strong> При наличии достаточной суммы депозита на счету Абонента, списать имеющиеся денежные
            средства для
            погашения существующей задолженности Абонента.
            <br>
            <strong>3.3.7.</strong> В одностороннем порядке вносить изменения в условия настоящую Публичную оферту/Договор,
            Тарифы/Тарифные
            планы, с обязательным уведомлением Абонента не менее чем за 10 дней до введения вышеуказанных изменений, путем
            размещения информации на сайте Оператора или уведомить любым удобным для Оператора способом.
            <br>
            <strong>3.3.8.</strong> Проводить в целях обеспечения безопасности при передаче данных и обеспечения надлежащего
            качества услуг,
            оказываемых Абоненту, технические мероприятия, предусматривающие приостановление оказания Услуг в соответствии с
            нормативно-правовыми актами.
            <br>
            <strong>3.3.9.</strong> Требовать возмещения любого ущерба, понесенного по вине Абонента.
            <strong>3.3.10.</strong> На основании гарантийного обязательства (для Абонентов кроме физических лиц)
            предоставить возможность
            пользоваться Услугами при отрицательном балансе Лицевого (электронного) счета Абонента, не более 30 (тридцать)
            дней. В гарантийном обязательстве Абонента указываются срок и сумма оплаты за пользование Услугами, в пределах
            суммы ежемесячной абонентской платы за пользование Услугами. Абонент признает все начисления, произведенные
            Оператором за оказанные, но не оплаченные Услуги в соответствии с Договором – бесспорными и обязуется оплатить
            их полностью в течение 5 календарных дней с даты выставления Оператором счета на оплату. Счет на оплату может
            быть отправлен по электронному документообороту, почте, в том числе по электронной почте, передан нарочно, либо
            через курьера.
            <br>
            <strong>3.3.11.</strong> Производить плановые технологические работы и иные виды работ, осуществлять
            модернизацию сети Оператора
            и обновлять программные средства, в связи с технической спецификой организации оказания Услуг, при этом Оператор
            в одностороннем порядке определяет параметры настроек программного обеспечения.
            <strong>3.3.12.</strong> В одностороннем порядке изменять технические параметры оказания Услуг в целях повышения
            качества и
            надёжности предоставляемых Услуг, в том числе изменения перечня предоставляемых программ, библиотек, функций,
            средств администрирования.
            <br>
            <strong>3.3.13.</strong> Произвести расформирование технических данных Абонента после приостановки оказания
            Услуг по причине их
            несвоевременной оплаты и не поступления на лицевой счёт Заказчика в течение 14 (четырнадцати) календарных дней
            оплаты, предусмотренной условиями Договора.
            <br>
            <strong>3.3.14.</strong> Произвести рекламных и информационных рассылок Абонентам путем размещения
            соответствующей информации в
            личном кабинете Абонента на сайте Оператора, публикацией в средствах массовой информации, путем рассылки SMS,
            либо на адрес электронной почты, а также другими способами, приемлемыми Оператору.
            <br>
            <strong>3.3.15.</strong> В одностороннем порядке расторгнуть Договор, предупредив об этом Абонента письменно за
            15 дней до даты
            расторжения, с учетом выполнения условий по настоящему Договору.
            <br>
            <strong>3.3.16.</strong> Оператор также может иметь другие права, предусмотренные законодательством Республики
            Узбекистан.
            <div class="mt-1 is-underlined">
                <strong>3.4. Абонент имеет право:</strong>
            </div>
            <strong>3.4.1.</strong> Пользоваться Услугами, предусмотренными Договором в соответствии с установленными
            Оператором Тарифами,
            техническими нормативами, стандартами Республики Узбекистан, соответствующими Правилами и условиями к выбранному
            виду услуги телекоммуникаций и условиями настоящего Договора.
            <br>
            <strong>3.4.2.</strong> Изменять Тариф/тарифный план, виды предоставляемых Услуг в установленном Оператором
            порядке, с учетом его
            технических возможностей.
            <br>
            <strong>3.4.3.</strong> На своевременное и качественное обслуживание Оператором в соответствии с техническими
            нормативами и
            стандартами Республики Узбекистан и настоящим Договором.
            <br>
            <strong>3.4.4.</strong> Требовать от Оператора выполнения обязательств, предусмотренными Договором, соблюдения
            установленных
            нормативов и норм качества и надежности предоставляемых услуг.
            <br>
            <strong>3.4.5.</strong> Получать информацию о потребительских свойствах и качестве оказываемых услуг.
            <br>
            <strong>3.4.6.</strong> Направлять рекламации и претензии в случае неисполнения или ненадлежащего исполнения
            обязательств
            Оператора по настоящему договору.
            <br>
            <strong>3.4.7.</strong> Получать детальную информацию о потребленном объеме Услуг, а также истории платежей,
            списаний и остатке
            денежных средств на Лицевом счете через Персональный кабинет (применимо к тем видам Услуг, в отношении которых
            предусмотрен доступ в Персональный кабинет) и или у Оператора.
            <br>
            <strong>3.4.8.</strong> Требовать от Оператора проведение перерасчета платежей при перерывах оказания услуг по
            вине Оператора.
            <br>
            <strong>3.4.9.</strong> Временно приостановить действие настоящего Договора сроком на один календарный месяц
            посредством
            письменного заявление за 10 дней до момента приостановления. Абонент не вносит абонентскую плату за этот период.
            Оплате подлежит стоимость резервирования услуги в соответствии с тарифами, опубликованными на сайте Оператора.
            Остаток неиспользованных средств переносится на последующие сроки действия Договора.
            <br>
            <strong>3.4.10.</strong> По письменному обращению получить детальный отчет от Оператора по объёму
            использованного трафика работы
            в Интернет посредством телефона или электронной почты.
            <br>
            <strong>3.4.11.</strong> Обращаться к Оператору за технической консультацией по телефону или непосредственно в
            офисе Оператора.
            <br>
            <strong>3.4.12.</strong> Обратится с заявкой на сайте Оператора в службу технической поддержки Оператора, и
            требовать устранения
            повреждения при ухудшении характеристик и параметров каналов связи от Оператора до Абонента.
            <br>
            <strong>3.4.13.</strong> Отказаться от Услуг в случае нарушения Оператором условий, оговоренных Договором, и в
            случае отсутствия
            задолженности по оплате Услуг, расторгнуть Договор с Оператором в одностороннем порядке.
            <br>
            <strong>3.4.14.</strong> Абонент может иметь другие права, предусмотренные законодательством Республики
            Узбекистан и настоящим
            Договором.
            <h4 class="has-text-centered m-2">IV. УСЛОВИЯ И ПОРЯДОК РАСЧЕТОВ</h4>
            <strong>4.1.</strong> Стоимость Услуг по настоящему Договору формируется из суммы единовременных и ежемесячных
            платежей,
            указанных в регистрационном бланке к настоящему договору, которое является неотъемлемой частью настоящего
            договора.
            <br>
            <strong>4.2.</strong> В стоимость Услуг включен налог на добавленную стоимость, за исключением случаев,
            указанных в
            соответствующих законодательных и нормативных актах.
            <br>
            <strong>4.3.</strong> Тарифы/Тарифные планы на все виды Услуг определяются Оператором самостоятельно.
            Утверждённый Прейскурант
            Тарифов/Тарифных планов размещается на сайте Оператора.
            <br>
            <strong>4.4.</strong> Услуги предоставляются на условиях 100% предоплаты Авансового платежа в размере,
            установленном
            соответствующим Тарифом/Тарифным планом, с учетом п. 3.3.10. Оператор ведет баланс неизрасходованной части
            предоплаты с момента начала пользования Услугами. Предоплата может быть использована Абонентом исключительно для
            возможности использования Услуг.
            <br>
            <strong>4.5.</strong> Оплата за предоставленные услуги осуществляется Абонентом путем перевода денежных средств
            на расчётный счет
            Оператора, любыми доступными методами перевода наличных или безналичных денежных средств, в том числе по
            средством сторонних платежных систем.
            <br>
            <strong>4.6.</strong> Платежи осуществляются в национальной валюте Республики Узбекистан.
            <br>
            <strong>4.7.</strong> При оплате счетов Абонент обязан указывать в платёжных банковских документах назначение
            платежа, и номер
            счета или номер телефона –для Абонентов физических лиц, номер договора для остальных Абонентов.
            <br>
            <strong>4.8.</strong> При оплате Абонентом счетов за используемые услуги в любом виде и посредством сторонних
            платежных систем,
            банков и касс, Оператор зачисляет на лицевой счет Абонента те средства, которые поступили на счет Оператора от
            сторонней платёжной системы, банка или кассы. Оператор не несет ответственность при возникновении споров
            связанных с переводом средств со сторонних платежных систем, банков и касс.
            <br>
            <strong>4.9.</strong> Начисление абонентской платы начинается с даты подключения Абонента к услугам Оператора,
            подтвержденной
            Актом ввода в эксплуатацию и начала предоставления услуг либо на основании регистрационного бланка к настоящему
            договору. Ежемесячная абонентская плата вносится 100% авансовым платежом, не позднее 1 (первого) числа
            календарного месяца, за который производится оплата, при этом абонентская плата может вноситься за период более
            1 (одного) месяца. Первого числа каждого Календарного месяца учтенные на Лицевом счете Абонента денежные
            средства уменьшаются на сумму, соответствующую Абонентской плате за текущий Календарный месяц.
            <br>
            <strong>4.10.</strong> Услуги предоставляются Абоненту на сумму, не превышающую положительный остаток
            Предоплаты. В случае
            снижения остатка Предоплаты Абонента до уровня, ниже необходимого для оплаты одного соединения Услуга не
            предоставляется.
            <br>
            <strong>4.11.</strong> В случаях, когда моментальное списание с Лицевого счета Абонента в размере потребляемых
            им Услуг
            невозможно, начисления за потребленные Услуги аккумулируются в отложенном режиме, а баланс Лицевого счета
            Абонента после перерасчета аккумулированных сумм становится отрицательным. Стороны устанавливают такую
            задолженность Абонента перед Оператором, как бесспорную, а Оператор приостанавливает оказание Услуг и оповещает
            Абонента об имеющейся задолженности любыми удобными Оператору способами, с требованием о ее погашении.
            <br>
            <strong>4.12.</strong> В случае отказа от Договора, положительный баланс на Лицевом счете Абонента, возвращается
            Абоненту в
            соответствии с условиями настоящего Договора.
            <br>
            <strong>4.13.</strong> В целях обеспечения исполнения обязательств Абонентом по своевременной оплате оказываемых
            ему Оператором
            услуг, Стороны вправе заключить Договор поручительства с третьим лицом (поручителем), обязующимся отвечать перед
            Оператором по обязательствам Абонента по оплате его счетов. Все отношения между Оператором, Абонентом и
            поручителем регулируются на основании настоящего Договора, договора поручительства и норм действующего
            законодательства, регулирующего данные отношения.
            <br>
            <strong>4.14.</strong> Услуги считаются оказанными надлежащим образом Оператором в течение каждого отчетного
            месяца, и принятыми
            Абонентом на последний день этого отчетного месяца, если в течение 5-ти календарных дней после окончания
            отчетного месяца Абонент не предъявил письменную обоснованную претензию, касающуюся объема и качества услуг.
            <br>
            <strong>4.15.</strong> Лицевой счет Абонента является основанием для проведения взаиморасчетов Сторон.
            <br>
            <strong>4.16.</strong> Абонентская плата, согласно регистрационному бланку, оплачивается в любом случае, кроме
            случаев, указанных
            в настоящем Договоре или прерывания оказания услуг по вине Оператора, с взаимным письменным уведомлением
            Оператора и Абонента.
            <br>
            <strong>4.17.</strong> В случае прерывания оказания услуг по вине Оператора стороны оформляют Акт сверки в
            течение двух рабочих
            дней следующего месяца. На основании Акта Оператор производит перерасчет платежей.
            <br>
            <strong>4.18.</strong> Подключение Абонента происходит в течение 5 рабочих дней после поступления на расчетный
            счет Оператора
            платы за подключение и абонентской платы за 1 месяц в соответствии с регистрационным бланком.
            <h4 class="has-text-centered mt-2">
                <strong>V. КАЧЕСТВО ПРЕДОСТАВЛЯЕМЫХ УСЛУГ</strong>
            </h4>
            <strong>5.1.</strong> Качество предоставляемых услуг должно соответствовать действующим стандартам и техническим
            требованиям
            Республики Узбекистан.
            <br>
            <strong>5.2.</strong> Оператор не несет ответственности за ухудшение качества предоставляемых Услуг или за их
            прекращение в
            случае, если такое ухудшение или прекращение явилось следствием естественных условий распространения и
            наложения радиоволн, нахождения Абонента вблизи или внутри зданий, в туннелях, в подвалах и других подземных
            сооружениях; локальных особенностей рельефа и застройки; технических характеристик и состояния оборудования
            Абонента, метеорологических условий и иных причин, на которые Оператор не в силах повлиять или предусмотреть их
            наступление.
            <h4 class="has-text-centered mt-2">
                <strong>VI. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
            </h4>
            <div class="is-underlined">
                <strong>6.1. Каждая из сторон:</strong>
            </div>
            - несет ответственность в порядке, установленном действующим законодательством РУз;
            <br>
            - не имеет права передавать свои права и обязанности третьим лицам без согласия другой стороны;
            <br>
            - соблюдает конфиденциальность информации об условиях настоящего договора бессрочно.
            <br>
            <strong>6.2.</strong> Оператор несет ответственность за задержки и перебои в работе, связанные с неисправностью
            станционного и
            сетевого оборудования Оператора и порядка использования Оператором информации о персональных данных Абонентов.
            <br>
            <div class="is-underlined mt-1">
                <strong>6.3. Абонент несет ответственность перед Оператором за:</strong>
            </div>
            <strong>6.3.1.</strong> Действие и\или бездействие, приведшие к разглашению, потере, краже и т. п. его
            Аутентификационных данных
            и иной информации, индивидуализирующей Абонента, а также за любые действия и\или бездействия третьих лиц,
            использующих учетные данные Абонента. Оператор не несет ответственность за вышеуказанные действия Абонента и\или
            третьих лиц, использующих его учетные данные.
            <br>
            <strong>6.3.2.</strong> Регулярную проверку наличия изменений в области оказания Услуг на сайте Оператора.
            Продолжение
            пользования услугами спустя 10 (десять) календарных дней после уведомления об изменении текущих условий
            настоящей Публичной оферты, тарифов и других изменений рассматривается как безоговорочное присоединение к
            данным изменениям и согласие с ними.
            <br>
            <strong>6.3.3.</strong> Совершение противоправных действий, противоречащих действующему законодательству
            Республики Узбекистан.
            <br>
            <strong>6.3.4.</strong> Самостоятельно отвечает за вред, причиненный его деяниями (лично или иным лицом по
            аутентификафионным
            данным) личности или имуществу: граждан, юридических лиц, государства или нравственным принципам общества.
            <br>
            <strong>6.3.5.</strong> Сохранение конфиденциальности переданных ему сетевых реквизитов и за соблюдение
            авторских прав и других
            законов, применимых к передаваемой или получаемой им информации.
            <br>
            <strong>6.3.6.</strong> Абонент несет полную материальную ответственность за сохранность полученного для
            предоставления услуг
            оборудования.
            <br>
            <strong>6.3.7.</strong> Абонент несет ответственность за нарушение правил эксплуатации абонентского
            оборудования.
            <br>
            <strong>6.4.</strong> Ответственность Сторон в иных случаях, непредусмотренных настоящим Договором, определяется
            в соответствии с
            действующим законодательством Республики Узбекистан, соответствующими правилами и условиями к выбранным услугам.
            <div class="is-underlined mt-1">
                <strong> 6.5. Оператор не несет ответственность за:</strong>
            </div>
            <strong>6.5.1.</strong> Неполучение Абонентом распространённой Оператором в порядке, предусмотренном настоящей
            Публичной оферты,
            информации об изменении тарифов, а также за неполучение Абонентом, по независящим от Оператора причинам.
            <br>
            <strong>6.5.2.</strong> Невыполнение обязательств по настоящему Договору при любых повреждениях сети передачи
            данных, не
            связанных с оборудованием или программным обеспечением Оператора.
            <br>
            <strong>6.5.3.</strong> Качество линий связи, если их организуют третьи лица.
            <br>
            <strong>6.5.4.</strong> Некачественный прием доставляемого Оператором телевизионного сигнала в случаях
            неисправности,
            неправильного использования абонентского оборудования, абонентской распределительной системы и/или абонентской
            линии, а также иных обстоятельств, не зависящих от Оператора.
            <br>
            <strong>6.5.5.</strong> За содержание ретранслируемых телепрограмм, изменение сетки вещания или за прекращение
            (приостановление)
            вещания тех или иных программ.
            <br>
            <strong>6.5.6.</strong> За перерывы в предоставлении Услуги по причине сбоя программного обеспечения или
            неисправности
            персонального компьютера, абонентского оборудования, принадлежащего Абоненту.
            <br>
            <strong>6.5.7.</strong> Несоответствие параметров качества и надежности услуг, возникшие по причине изменения
            Абонентом
            конфигурации сети.
            <br>
            <strong>6.5.8.</strong> Полные или частичные прерывания предоставления услуг, связанные с заменой оборудования,
            программного
            обеспечения или проведения других работ, вызванных необходимостью поддержания работоспособности и развития сети.
            <br>
            <strong>6.5.9.</strong> Функционирование и доступность отдельных сегментов сети Оператора.
            <br>
            <strong>6.5.10.</strong> За содержание информации, передаваемой Абонентом по сети, а также за прямой или
            косвенный ущерб,
            понесённый Абонентом в результате использования или невозможности использования Услуг Оператора.
            <br>
            <strong>6.5.11.</strong> В случаях длительного непредставления Услуг Абоненту по техническим причинам и
            форс-мажорным
            обстоятельствам, в том числе: а) периодов обрыва или деградации каналов и оборудования связи, не находящихся в
            ведении или собственности Оператора; б) периодов непредставления Услуг, напрямую или косвенно вызванных или
            спровоцированных Абонентом, а также третьим лицом, использующим Услугу с согласия Абонента. Под этим понимаются
            не согласованные с техническими службами Оператора аппаратные или программные модификации, а также
            самостоятельные переустановки сетевых реквизитов; в) периодов продолжительностью менее 30 (тридцати) минут.
            <br>
            <strong>6.5.12.</strong> Ущерб любого рода, понесенный Абонентом из-за разглашения им своей учетной информации,
            нарушения правил
            информационной безопасности, действия третьих лиц. В частности, несанкционированного почтового обмена, вирусных
            атак и т.п.
            <br>
            <strong>6.5.13.</strong> Ущерб любого рода, понесенный Абонентом в результате возникновения непреодолимого
            технического или
            юридического препятствия к продолжению оказания Услуг Оператором.
            <br>
            <strong>6.5.14.</strong> Ущерб прямой или косвенный, понесённый Абонентом в результате использования или
            невозможности
            использования услуг, возникший не по вине Оператора.
            <br>
            <strong>6.5.15.</strong> Оператор не несет ответственность перед Абонентом по возмещению убытков в виде
            упущенной выгоды.
            <h4 class="has-text-centered mt-2">
                <strong> VII. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</strong>
            </h4>
            <strong>7.1.</strong> Ни одна из Сторон не будет нести ответственность за полное или частичное неисполнение
            условий настоящего
            Договора, если оно явилось следствием обстоятельств непреодолимой силы (форс-мажор), включая, но, не
            ограничиваясь: наводнение, землетрясение, сель, война, издание актов государственных органов, а также действий,
            бездействий со стороны государственных органов, запрещающих каким-либо образом исполнение настоящего договора, и
            других обстоятельств, возникших в период действия Договора.
            <br>
            <strong>7.2.</strong> О возникновении форс-мажорных обстоятельств Стороны должны незамедлительно уведомить друг
            друга.
            <br>
            <strong>7.3.</strong> Форс-мажорные обстоятельства должны быть подтверждены документами компетентных органов.
            <br>
            <strong>7.4.</strong> Не уведомление или несвоевременное уведомление лишает Сторону права ссылаться на любое
            вышеуказанное
            обстоятельство, как на основание, освобождающее от ответственности за неисполнение обязательств.
            <br>
            <strong>7.5.</strong> Стороны возобновляют взаимоотношения по данному Договору в течение 24 часов по окончании
            форс-мажорных
            обстоятельств после взаимного оповещения.
            <h4 class="has-text-centered mt-2">
                <strong>VIII. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</strong>
            </h4>
            <strong>8.1.</strong> Споры и разногласия, которые могут возникнуть при исполнении настоящего Договора, будут по
            возможности
            решаться путем переговоров между Сторонами.
            <br>
            <strong>8.2.</strong> Досудебный претензионный порядок рассмотрения споров обязателен. Срок рассмотрения
            претензии
            устанавливается 10 (десять) календарных дней с даты ее получения.
            <br>
            <strong>8.3.</strong> При разногласиях, возникших в результате пользования Услугами третьими лицами путем
            несанкционированного
            доступа к Сети, Оператор вправе произвести внутреннее техническое расследование в установленном порядке для
            определения принадлежности совершенных действий.
            <br>
            <strong>8.4.</strong> В случае возникновения сомнений у Оператора касательно добросовестности пользования
            Абонентом Услугами
            Оператора, последний направляет любым приемлемым способом уведомление Абоненту о явке в Офис Оператора для
            выяснения обстоятельств и возможном приостановлении оказания Услуг. Если уведомление Оператора не исполнено
            Абонентом в течение 3-х календарных дней со дня направления уведомления, Оператор вправе приостановить оказание
            Услуг до выполнения Абонентом требуемых Оператором в уведомлении условий.
            <br>
            <strong>8.5.</strong> Задолженность Абонента перед Оператором, возникшая за оказанные Услуги и неоплаченная им в
            добровольном
            порядке, подлежит принудительному взысканию в порядке искового производства, в порядке приказного производства,
            а также в бесспорном порядке на основании исполнительных надписей, совершаемых нотариусами.
            <br>
            <strong>8.6.</strong> В случае невозможности достижения обоюдного согласия Сторон, возникший спор
            рассматривается в суде, в
            установленном законодательством Республики Узбекистан порядке.
            <h4 class="has-text-centered mt-2">
                <strong>IX. СРОК ДЕЙСТВИЯ ДОГОВОРА, УСЛОВИЯ ЕГО ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ</strong>
            </h4>
            <strong>9.1.</strong> Договор вступает в силу с момента акцепта Абонентом настоящей Публичной оферты, и
            действует в течении
            срока, указанного в договоре, а при его отсутствии считается заключенным на неопределённый срок.
            <br>
            <strong>9.2.</strong> Изменения в условия Договора в части порядка оказания Услуг телекоммуникаций (изменение
            Тарифных планов,
            порядка расчетов за Услуги телекоммуникаций и т.п.) производятся Оператором в одностороннем порядке, путем
            направления Абоненту соответствующих извещений (в том числе путем размещения соответствующей информации на
            сайте Оператора, публикацией в средствах массовой информации, путем рассылки SMS, либо на адрес электронной
            почты, а также другими способами, приемлемыми Оператору).
            Изменения считаются принятыми Абонентом, если Оператор в течение 10 (десять) календарных дней с даты направления
            Абоненту соответствующих уведомлений или размещения/публикации соответствующей информации, не получит от
            Абонента письменный отказ от таких изменений. Если в течение вышеупомянутого срока Оператор получит письменный
            отказ Абонента от принятия таких изменений, то Договор с Абонентом будет считаться расторгнутым по инициативе
            Абонента в день истечения вышеупомянутого десятидневного срока.
            <br>
            <strong>9.3.</strong> По желанию сторон договор может быть изменен или дополнен по взаимному согласию сторон.
            Сторона, выступающая инициатором изменений или дополнений, уведомляет другую Сторону о своем намерении в
            письменном виде за 15 дней до внесения изменений.
            Все изменения и дополнения оформляются в письменном виде, подписанные уполномоченными представителями Сторон,
            либо публикуются на сайте Оператора за 15 дней до внесения изменений. В случае несогласия Абонента с
            предлагаемыми изменениями, Абонент информирует об этом Оператора в письменном виде и Договор расторгается.
            Отсутствие письменного заявления абонента о несогласии с изменениями считается согласием Абонента с этими
            изменениями и с оплатой Абонентом по новым тарифам.
            <div class="is-underlined mt-1">
                <strong>9.4. Договор может быть расторгнут в следующих случаях:</strong>
            </div>
            <strong>9.4.1.</strong> По инициативе Абонента, как с указанием причины расторжения, так и без указания. При этом, при
            расторжении Договора Абонент (кроме физических лиц) извещает Оператора об одностороннем расторжении не менее чем
            за 10 (десять) календарных дней до предполагаемой даты расторжения.
            <br>
            <strong>9.4.2.</strong> По инициативе Оператора в следующих случаях:
            - нарушения Абонентом условий Договора;
            <br>
            - невозможности исполнения обязательств (отсутствия Технической возможности предоставления услуг; вследствие
            обстоятельств непреодолимой силы, издание акта государственного органа, вследствие которого исполнение
            обязательства становится невозможным, в частности, отзыв, приостановление, аннулирование у Оператора лицензии в
            установленном законом порядке);
            <br>
            - в случае смерти Абонента, при предоставлении подтверждающего документа;
            <br>
            - не осуществления Абонентом в течение 60 (шестьдесят) календарных дней подряд транзакций, дающих объем прав на
            получение Услуг телекоммуникаций, с даты приостановления оказания Услуг телекоммуникаций мобильной связи;
            <br>
            - в случае выявления фактов фрода со стороны Абонента;
            <br>
            - в иных случаях, предусмотренных настоящим Договором или законодательством Республики Узбекистан.
            <br>
            <strong>9.5.</strong> С момента отказа Абонента от исполнения Договора или расторжения Договора по другим основаниям,
            обязательства Сторон считаются прекращенными, за исключением обязательств Абонента, связанных с оплатой
            оказанных, но не оплаченных на момент расторжения Договора Услуг телекоммуникаций.
            <br>
            <strong>9.6.</strong> При расторжении Договора положительный баланс средств на лицевом (электронном) счете Абонента, возвращается
            Абоненту путем перевода остатка неизрасходованных денежных средств на его банковский счет или на его пластиковую
            банковскую карту. При этом Абонент обязан подать Оператору соответствующее письменное заявление, предъявить
            Удостоверение личности и предоставить информацию о его банковских реквизитах или его пластиковой банковской
            карте. Денежные средства возвращаются Абоненту в срок не более 30-ти календарных дней с даты получения
            Оператором заявления. При этом при расторжении Договора, установленная условиями Договора плата за подключение
            Абонентского устройства/Пользовательского оборудования Абоненту не возвращается. Оплата услуги за выбор
            абонентского номера из платной категории абонентских номеров, Оператором не возмещается. При повторном
            заключении Договора сохранение ранее предоставленного Абонентского номера Абоненту не гарантируется.
            <br>
            <strong>9.7.</strong> С момента расторжения Договора, обязательства Сторон прекращаются за исключением обязательств Абонента,
            связанных с оплатой оказанных, но не оплаченных на момент расторжения Договора Услуг телекоммуникаций.
            <br>
            <strong>9.8.</strong> Расторжение настоящего договора не освобождает Стороны от ответственности за несоблюдение условий Договора.
            <h4 class="has-text-centered mt-2">
                <strong>X. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
            </h4>
            <strong>10.1.</strong> Стороны настоящим обязуются соблюдать требования антикоррупционного законодательства и не предпринимать
            никаких действий, которые могут нарушить нормы антикоррупционного законодательства, в связи со своими правами
            или обязательствами согласно настоящему Договору, в том числе (не ограничиваясь) не совершать предложение,
            санкционирование, обещание и осуществление незаконных платежей, включая (но, не ограничиваясь) взятки в денежной
            или любой иной форме, каким-либо физическим или юридическим лицам, включая (но, не ограничиваясь) коммерческие
            организации, органы власти и самоуправления, государственных служащих, частных компаний и их представителей.
            В случае нарушения одной из Сторон обязательств, указанных в части первой настоящего пункта другая Сторона имеет
            право в одностороннем внесудебном порядке отказаться от исполнения настоящего Договора. Стороны не возмещают
            друг другу убытки в случае расторжения договора в соответствии с данным пунктом.
            <br>
            <strong>10.2.</strong> Все правоотношения Сторон, возникающие в связи с оказанием Абоненту Услуг телекоммуникаций прямо не
            урегулированные настоящим Договором, регулируются действующими нормативно-правовыми актами Республики
            Узбекистан.
            <br>
            <strong>10.3.</strong> По всем вопросам, неурегулированным настоящей Офертой, Стороны руководствуются действующим
            законодательством Республики Узбекистан.
            <br>
            <strong>10.4.</strong> Все приложения являются неотъемлемой частью настоящего договора.
            <br>
            <strong>10.5.</strong> На дополнительные виды услуг, не перечисленные в Тарифах, но входящие в предмет данного Договора, а также
            при особых условиях выполнения данного Договора, подписываются дополнительные соглашения, которые являются
            неотъемлемой частью заключенного Договора. Время действия и условия выполнения дополнительных видов услуг и
            особых условий выполнения данного Договора определяется в дополнительном соглашении.
        </div>
    </div>
    <hr>
    <ScrollButton @scrollTop="scrollTop"/>
    <UTFooter />
</template>


<script>
import BaseNavbar from '@/components/Base/BaseNavbar.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import ScrollButton from '@/components/Base/ScrollButton.vue';
export default {
    name: "PublicOfferView",
    components: {
        BaseNavbar,
        UTFooter,
        ScrollButton
    },
    methods: {
        scrollTop() {
            let viewPort = window.scrollY
            if (viewPort > 500) {
                window.scrollTo(0, 0);
                this.isHidden = false
            }

        },
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>