<template>
    <div class="m-4">
        <BaseNavbar />

    </div>
   
    <section class="section">
        <div class="container">
            <Benefits />
        </div>
    </section>
    <UTFooter />
</template>

<script>
import Benefits from '@/components/Home/Benefits.vue'
import UTFooter from '@/components/Base/UTFooter.vue';
import BaseNavbar from '@/components/Base/BaseNavbar.vue';
import Navbar from '@/components/Base/Navbar.vue';
export default {
    name: "AdvantagesVIew",
    components: {
        Navbar,
        UTFooter,
        BaseNavbar,
        Benefits
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>
