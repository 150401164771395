<template>
    <section class="hero is-medium has-text-white is-relative box"
        style="font-family: RoadRadio; letter-spacing: 0.5px; font-size: 1.25rem;">
        <!-- Hero head: will stick at the top -->
        <div class="overlay"></div>
        <div class="hero-head">
            <nav class="navbar is-hidden-tablet" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <router-link :to="{ name: 'home' }">
                        <img src="@/assets/logo.svg" width="100">
                    </router-link>

                    <a role="button" class="navbar-burger has-text-white" @click="showBurgerMenu"
                        :class="{ 'is-active': isShowBurgerMenu }" aria-label="menu" aria-expanded="false"
                        data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isShowBurgerMenu }">
                    <div class="navbar-start">
                        <a class="navbar-item" href="#contacts">Контакты</a>
                        <router-link :to="{ name: 'partners' }" class="navbar-item">Партнеры</router-link>
                        <router-link :to="{ name: 'tarrifs' }" class="navbar-item">Тарифы</router-link>
                        <router-link :to="{ name: 'coverage-zone' }" class="navbar-item">Зона покрытия</router-link>
                        <router-link :to="{ name: 'services' }" class="navbar-item">Услуги</router-link>
                        <a href="https://my.urbantelecom.uz" class="navbar-item">Личный кабинет</a>

                    </div>

                </div>
            </nav>

            <nav class="level is-relative is-hidden-mobile" style="z-index: 10;">
                <p class="level-item has-text-centered">
                    <a class="ut-link" href="#contacts">Контакты</a>
                </p>
                <p class="level-item has-text-centered">
                    <router-link :to="{ name: 'partners' }" class="ut-link">Партнеры</router-link>
                </p>
                <p class="level-item has-text-centered">
                    <router-link :to="{ name: 'services' }" class="ut-link">Услуги</router-link>
                </p>
                <p class="level-item has-text-centered">
                    <img src="@/assets/logo.svg" alt="" style="height: 100px;">
                </p>
                <p class="level-item has-text-centered">
                    <router-link :to="{ name: 'tarrifs' }" class="ut-link">Тарифы</router-link>
                </p>
                <p class="level-item has-text-centered">
                    <router-link :to="{ name: 'coverage-zone' }" class="ut-link">Зона покрытия</router-link>
                </p>
                <p class="level-item has-text-centered">
                    <a href="https://my.urbantelecom.uz" class="ut-link has-tooltip" data-tooltip="Личный кабинет">ЛК</a>
                </p>
            </nav>
        </div>

        <div class="hero-video" style="display: block;">
            <video autoplay loop muted playsinline id="video-background" style="object-fit: cover;">
                <source src="@/assets/video.mp4" type="video/mp4">
            </video>
        </div>
        <!-- Hero content: will be in the middle -->
        <div class="hero-body">
            <div class="" style="z-index:10; position: relative;">
                <p class="ut-title">Ваша улыбка наш успех</p>
                <NavbarCards />
            </div>
        </div>
    </section>
</template>
<script>
import NavbarCards from '@/components/Home/NavbarCards.vue'
export default {
    name: "Navbar",
    components: {
        NavbarCards
    },
    data() {
        return {
            isShowBurgerMenu: false,
        }
    },
    methods: {
        showConnectModal() {
            this.$emit('showConnectModal')
        },
        showBurgerMenu() {
            if (this.isShowBurgerMenu === false) {
                this.isShowBurgerMenu = true
            }
            else {
                this.isShowBurgerMenu = false
            }
        },
    }
}
</script>
<style scoped>
@import '@creativebulma/bulma-tooltip';

.button {
    font-weight: 500;
    letter-spacing: 1px;
    background-color: #702bc0;
    font-family: RoadRadio;
    transition: all 0.5s ease;
}

.button:hover {
    background-color: #271856;
}

.overlay {
    background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}


.ut-link {
    color: white;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;

}

.ut-link:after {
    display: block;
    content: '';
    border-bottom: solid 3px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;


}

.ut-link:hover {
    color: white;
}

.ut-link:hover:after {

    transform: scaleX(1);
}

.ut-link:after {


    transform-origin: 0% 50%;
}

.ut-title {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
}

@media(max-width: 425px) {
    .navbar-item {
        font-size: 14px;
    }

    .ut-title {
        font-size: 35px;
    }
}
@media(max-width: 320px) {
    .navbar-item {
        font-size: 14px;
    }

    .ut-title {
        font-size: 30px;
    }
}
</style>