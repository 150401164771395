<template>
	<div class="container">
		<div class="tabs is-toggle">
			<ul class="ut-tabs">
				<li :class="{ 'is-active': isShowNews }" id="news" @click="selectNewsTab($event)">
					<a>
						<font-awesome-icon icon="newspaper" />
						<span class="ml-2 ut-tabs-item">Новости</span>
					</a>
				</li>
				<li id="alert" :class="{ 'is-active': isShowAlert }" @click="selectNewsTab($event)">
					<a>
						<font-awesome-icon icon="gears" />
						<span class="ml-2 ut-tabs-item">Тех.работы</span>
					</a>
				</li>
				<li id="stock" :class="{ 'is-active': isShowStock }" @click="selectNewsTab($event)">
					<a>
						<font-awesome-icon icon="fire" />
						<span class="ml-2 ut-tabs-item">Акции</span>
					</a>
				</li>
			</ul>
		</div>
		<div>
			<div class="ut-news" v-show="isShowNews" style="overflow-y: auto;">
				<div v-if="news.length > 0">
					<div v-for="notification in news" :key="notification.id">
						<div class="news-item notification is-info is-light mb-1">
							<div class="news-item-header">
								<div class="news-item-header-date is-flex">
									<div class="news-item-header-date-date mr-2">
										{{ notification.news_created_at }}
									</div>
									<div class="tag is-info is-rounded" style="font-family: RoadRadio; 
									letter-spacing: 0.5px;">
										Новость
									</div>
								</div>
								<div class="news-item-header-body" @click="pushNotificationDetail(notification.id)">
									{{ notification.news_name }}
								</div>
							</div>
						</div>
					</div>
					<button class="button is-link is-outlined is-rounded mt-3" @click="getMoreNotifications"
						v-show="isShowNextNews" style="font-family: Littera Plain;">Показать еще</button>
				</div>
				<div v-else>
					<div class=" notification is-info is-light" style="font-family: Littera Plain; letter-spacing: 0.5px;">
						Мы обязательно уведомим с первой новостью</div>
				</div>
			</div>



			<div class="ut-alert" v-show="isShowAlert">
				<div v-if="alerts.length > 0">
					<div v-for="alert in alerts" :key="alert.id">
						<div class="news-item notification is-info is-light mb-1">
							<div class="news-item-header">
								<div class="news-item-header-date is-flex">
									<div class="news-item-header-date-date mr-2">
										{{ alert.alert_created_at }}
									</div>
									<div class="tag is-warning is-rounded"
										style="font-family: RoadRadio; letter-spacing: 0.5px;">
										Оповещение</div>
								</div>
								<div class="news-item-header-body" @click="pushAlertDetail(alert.id)">
									{{ alert.alert_name }}
								</div>
							</div>
						</div>
					</div>
					<button class="button is-link is-outlined is-rounded mt-3" @click="getMoreNotifications"
						v-show="isShowNextAlerts" style="font-family: Littera Plain;">Показать еще</button>
				</div>
				<div v-else class="">
					<div class=" notification is-info is-light" style="font-family: Littera Plain; letter-spacing: 0.5px;">
						Мы обязательно уведомим при первом случаи тех.работ.</div>
				</div>
			</div>


			<div class="ut-stock" v-show="isShowStock">
				<div v-if="stocks.length > 0">
					<div v-for="stock in stocks" :key="stock.id">
						<div class="news-item notification is-info is-light mb-1">
							<div class="news-item-header">
								<div class="news-item-header-date is-flex">
									<div class="news-item-header-date-date mr-2">
										{{ stock.stock_created_at }}
									</div>
									<div class="tag is-primary is-rounded"
										style="font-family: RoadRadio; letter-spacing: 0.5px;">
										Акция</div>
								</div>
								<div class="news-item-header-body" @click="pushStockDetail(stock.id)">
									{{ stock.stock_name }}
								</div>
							</div>
						</div>
					</div>
					<button class="button is-link is-outlined is-rounded mt-3" @click="getMoreNotifications"
						v-show="isShowNextStocks" style="font-family: Littera Plain;">Показать еще</button>
				</div>
				<div v-else>
					<div class=" notification is-info is-light" style="font-family: Littera Plain; letter-spacing: 0.5px;">
						Мы обязательно уведомим о новых акциях!</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import axios from 'axios'

export default {
	name: "News",
	components: {
		FontAwesomeIcon
	},
	data() {
		return {
			currentPage: 1,
			isShowNextNews: false,
			isShowNextAlerts: false,
			isShowNextStocks: false,
			isShowNews: true,
			isShowAlert: false,
			isShowStock: false,
			news: [],
			alerts: [],
			stocks: []
		}
	},
	methods: {
		pushNotificationDetail(notificationId) {
			let newsId = this.news.filter((x) => x.id == notificationId)
			this.$router.push({
				name: 'NewsDetailView',
				params: {
					id: newsId[0].id,
					type: "news"
				}
			})
			this.$emit('pushNotificationDetail', 'news')
		},
		pushAlertDetail(notificationId) {
			let alertId = this.alerts.filter((x) => x.id == notificationId)
			this.$router.push({
				name: 'NewsDetailView',
				params: {
					id: alertId[0].id,
					type: "alert"
				}
			})
			this.$emit('pushAlertnDetail', 'alert')
		},
		pushStockDetail(notificationId) {
			let stockId = this.stocks.filter((x) => x.id == notificationId)
			this.$router.push({
				name: 'NewsDetailView',
				params: {
					id: stockId[0].id,
					type: "stock"
				}
			})
			this.$emit('pushStockDetail', 'stock')
		},
		selectNewsTab(selectTab) {
			if (selectTab.currentTarget.id === 'news') {
				this.isShowAlert = false
				this.isShowStock = false
				this.isShowNews = true
			} else if (selectTab.currentTarget.id === 'alert') {
				this.isShowNews = false
				this.isShowStock = false
				this.isShowAlert = true
			} else if (selectTab.currentTarget.id === 'stock') {
				this.isShowNews = false
				this.isShowAlert = false
				this.isShowStock = true
			}
		},
		getNews() {
			axios
				.get('/api/v1/get-news/')
				.then(response => {
					if (response.data.results) {
						this.news = response.data.results
					}
					if (response.data.next) {
						this.isShowNextNews = true
					}
					if (!response.data.next) {
						this.isShowNextNews = false
					}
				})


		},
		getAlerts() {
			axios
				.get('/api/v1/get-alerts/')
				.then(response => {
					if (response.data.results) {
						this.alerts = response.data.results
					}
					if (response.data.next) {
						this.isShowNextAlerts = true
					}
					if (!response.data.next) {
						this.isShowNextAlerts = false
					}
				})
		},
		getStocks() {
			axios
				.get('/api/v1/get-stocks/')
				.then(response => {
					if (response.data.results) {
						this.stocks = response.data.results
					}
					if (response.data.next) {
						this.isShowNextStocks = true
					}
					if (!response.data.next) {
						this.isShowNextStocks = false
					}
				})
		},
		getMoreNotifications() {
			this.currentPage++



			if (this.isShowNextNews) {
				axios
					.get(`/api/v1/get-news/?page=${this.currentPage}`)
					.then(response => {
						if (response.data.results) {
							this.news.push(...response.data.results)
						}
						if (response.data.next) {
							this.isShowNextNews = true
						}
						if (!response.data.next) {
							this.isShowNextNews = false
						}
					})
			}


			if (this.isShowNextAlerts) {
				axios
					.get(`/api/v1/get-alerts/?page=${this.currentPage}`)
					.then(response => {
						if (response.data.results) {
							this.alerts.push(...response.data.results)
						}
						if (response.data.next) {
							this.isShowNextAlerts = true
						}
						if (!response.data.next) {
							this.isShowNextAlerts = false
						}
					})
			}


			if (this.isShowNextStocks) {
				axios
					.get(`/api/v1/get-stocks/?page=${this.currentPage}`)
					.then(response => {
						if (response.data.results) {
							this.stocks.push(...response.data.results)
						}
						if (response.data.next) {
							this.isShowNextStocks = true
						}
						if (!response.data.next) {
							this.isShowNextStocks = false
						}
					})
			}

		}
	},
	mounted() {

		this.getNews()
		this.getAlerts()
		this.getStocks()


	}

}
</script>

<style scoped>
.ut-tabs-item {
	font-family: Littera Plain;
}

.news-item-header-body {
	cursor: pointer;
}
.news-item-header-body:hover {
	text-decoration: underline;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.ut-tabs {
	display: flex;
	justify-content: center;

	font-family: RoadRadio;
}

.tabs>ul>li {
	min-width: 200px;
	max-width: 200px;
}

@media(max-width: 425px) {
	.ut-tabs {
		display: flex;
		flex-direction: column;
		align-items: self-start;
	}

	.tabs>ul>li {
		min-width: 100%;
		max-width: 100%;
	}

}

@media(max-width: 768px) {

.title {
	font-size: 25px;
	font-weight: 900;
}
.ut-tabs-item {
	font-size: 17px;
	font-family: "Littera Plain";
	letter-spacing: 0.5px;
}

}
@media(max-width: 320px) {
.ut-tabs {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-items: center;
}
.title {
	font-size: 15px;
	font-weight: 900;
}
.ut-tabs-item {
	font-size: 14px;
	font-family: "Littera Plain";
	letter-spacing: 0.5px;
}

.ut-news {
	font-size: 14px;
}

.ut-alert {
	font-size: 14px;
}

.ut-stock {
	font-size: 14px;
}
}

.tag {
	font-size: 10px;
}

.button {
	font-size: 14px;
}


</style>