<template>
    <div class="column">
        <div class="cards">
            <div class="card ut-overlay">
                <div class="card-content">
                    <div class="content has-text-white">
                        <div class="content is-flex is-justify-content-center is-align-items-center">
                            <div class="mr-5">
                                <font-awesome-icon icon="fire" size="2xl" />
                            </div>
                            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                                <span class="title has-text-white">
                                    <slot name="first-title"></slot>
                                </span>
                                <span class="subtitle has-text-white" style="font-family: Littera Plain;">
                                    <slot name="first-subtitle"></slot>

                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="card ut-overlay">
                <div class="card-content">
                    <div class="content has-text-white">
                        <div class=" content has-text-white is-flex is-justify-content-center is-align-items-center">
                            <div class="mr-5">
                                <font-awesome-icon icon="gauge-high" size="2xl" />
                            </div>
                            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                                <span class="title has-text-white">
                                    <slot name="second-title"></slot>

                                </span>
                                <span class="subtitle has-text-white" style="font-family: Littera Plain;">
                                    <slot name="second-subtitle">

                                    </slot>

                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card ut-overlay">
                <div class="card-content">
                    <div class="content has-text-white">
                        <div class=" content has-text-white is-flex is-justify-content-center is-align-items-center">
                            <div class="mr-5">
                                <font-awesome-icon icon="globe" size="2xl" />
                            </div>
                            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                                <span class="title has-text-white">
                                    <slot name="third-title"></slot>

                                </span>
                                <span class="subtitle has-text-white" style="font-family: Littera Plain;">
                                    <slot name="third-subtitle"></slot>

                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="column">
        
    </div> -->
</template>

<script>
import BaseCarousel from '@/components/Base/BaseCarousel.vue'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
export default {
    name: "ServicesNavbarCards",
    components: {
        FontAwesomeIcon,
        BaseCarousel
    }
}
</script>

<style scoped>
.cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ut-overlay {
    background: linear-gradient(179.27deg, #d9d7e073 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
    border-radius: 15px;
}

.card {
    max-width: 400px;
    min-width: 400px;

    margin: 5px;
}



@media(max-width: 768px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        min-width: 350px;
        max-width: 350px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }

    .title {
        font-size: 25px;
        font-weight: 900;
    }
    .subtitle {
        font-size: 15px;
    }
}

@media(max-width: 320px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        min-width: 250px;
        max-width: 250px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }

    .title {
        font-size: 15px;
        font-weight: 900;
    }

    .subtitle {
        font-size: 13px;
    }


}
</style>