<template>
    <div class="m-4">
        <BaseNavbar />

    </div>
    <section class="section has-text-centered mt-5">
        <p class="title " style="font-family: RoadRadio;">Прайс-лист тех.обслуживания</p>
    </section>
    <section class="section" style="font-family: Littera Plain;">
        <div class="container">
            <p class="title is-size-4 notification is-info is-light" style="font-family: RoadRadio;">Услуги по обслуживанию
                сетевого оборудования и сетей
                передачи данных</p>
            <table class="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Стоимость</th>
                    </tr>
                </thead>
                <tbody class="container">
                    <tr>
                        <td>Стандартная настройка роутера</td>
                        <td>100 000 сум / за 1 ед.</td>
                    </tr>
                    <tr>
                        <td>Комплексная настройка роутера</td>
                        <td>200 000 сум / за 1 ед.</td>
                    </tr>
                    <tr>
                        <td>Диагностика и тестирование оборудования </td>
                        <td> от 70 000 до 150 000 сум
                            (в зависимости от сложности работ) </td>
                    </tr>
                    <tr>
                        <td>Настройка рабочей станции в локальной сети</td>
                        <td>25 000 сум / за 1 ед</td>
                    </tr>
                    <tr>
                        <td>Настройка прочего сетевого оборудования</td>
                        <td>Договорная</td>
                    </tr>
                </tbody>
            </table>

            <p class="title is-size-4 notification is-info is-light" style="font-family: RoadRadio;">Установка и настройка
                программного обеспечения</p>
            <table class="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Стоимость</th>
                    </tr>
                </thead>
                <tbody class="container">
                    <tr>
                        <td>Установка и настройка ОС Windows7/8/10/11</td>
                        <td>300 000 сум / за 1 ед.</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка стандарного ПО</td>
                        <td>100 000 сум / за 1 ед.</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка стороннего ПО</td>
                        <td>100 000 сум / за 1 ед.</td>
                    </tr>
                    <tr>
                        <td>Настройка рабочей станции в локальной сети</td>
                        <td>25 000 сум / за 1 ед</td>
                    </tr>
                    <tr>
                        <td>Настройка прочего сетевого оборудования</td>
                        <td>Договорная</td>
                    </tr>
                </tbody>
            </table>

            <p class="title is-size-4 notification is-info is-light" style="font-family: RoadRadio;">Установка и настройка
                программного обеспечения (с
                дистрибутива заказчика) для серверов
            </p>
            <table class="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Стоимость</th>
                    </tr>
                </thead>
                <tbody class="container">
                    <tr>
                        <td>Установка и настройка ОС WindowsServer 2000/2003/2008/2012</td>
                        <td>Договорная
                        </td>
                    </tr>
                    <tr>
                        <td>Установка и настройка ОС WindowsServer 2000/2003/2008/2012 в качестве файлового сервера </td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка ОС WindowsServer 2000/2003/2008/2012 в качестве интернет-сервера </td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка ОС WindowsServer 2000/2003/2008/2012 в качестве контроллера домена </td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка ОС Linux в качестве шлюза</td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка Proxy -сервера</td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка WEB-сервера </td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка VPN-сервера </td>
                        <td>Договорная</td>
                    </tr>
                    <tr>
                        <td>Установка и настройка системы учета и контроля потребления интернет-трафика </td>
                        <td>Договорная</td>
                    </tr>
                </tbody>
            </table>

            <p class="title is-size-4 notification is-info is-light" style="font-family: RoadRadio;">Монтажно-кабельные
                работы</p>
            <table class="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Стоимость</th>
                    </tr>
                </thead>
                <tbody class="container">
                    <tr>
                        <td>Монтаж FTP/UTP витая пара категории 5е внутри помещения абонента (открытым способом c помощью
                            скоб):
                            - протяженностью до 30 метров;<br>
                            - протяженностью от 30 до 100 метров;<br>
                            - протяженностью свыше 100 метров
                        </td>
                        <td>- 100 000 сум / за 1 точку;<br>
                            - 200 000 сум / за 1 точку;<br>
                            - договорная
                        </td>
                    </tr>
                    <tr>
                        <td>Стоимость реализации Ethernet кабеля 5е категории</td>
                        <td>3000 сум / за 1 метр
                        </td>
                    </tr>
                    <tr>
                        <td>Монтаж сетевого короба, пластикового канала </td>
                        <td>5 000 сум / за 1 метр
                        </td>
                    </tr>
                    <tr>
                        <td>Cтоимость реализации сетевой розетки Cat.5е </td>
                        <td>20 000 сум / за 1 ед.
                        </td>
                    </tr>
                    <tr>
                        <td>Монтаж сетевой розетки RJ-45* </td>
                        <td>30 000 сум / за 1 ед.
                        </td>
                    </tr>
                    <tr>
                        <td>Cтоимость реализации коннектора RJ 45 </td>
                        <td>3000 сум / за 1 метр
                        </td>
                    </tr>
                    <tr>
                        <td>Монтаж коннекторов RJ-45 </td>
                        <td>30 000 сум / за 1 метр
                        </td>
                    </tr>
                    <tr>
                        <td>Монтаж свитч-хаба </td>
                        <td>40 000 сум / за 1 ед.
                        </td>
                    </tr>
                    <tr>
                        <td>Просверливание отверстий (диаметром не более 1,5 см) в стенах и потолках (толщиной не более 50
                            см) </td>
                        <td>40 000 сум / за 1 отверстие
                        </td>
                    </tr>
                </tbody>
            </table>

            <p class="title is-size-4 notification is-info is-light" style="font-family: RoadRadio;">Другие виды услуг</p>
            <table class="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Стоимость</th>
                    </tr>
                </thead>
                <tbody class="container">
                    <tr>
                        <td>Устранение стандартной локальной проблемы

                        </td>
                        <td>от 50 000 до 100 000 сум
                            (в зависимости от сложности работ)
                        </td>
                    </tr>
                    <tr>
                        <td>Устранение комплексной проблемы </td>
                        <td>от 100 000 до 300 000 сум
                            (в зависимости от сложности работ)
                        </td>
                    </tr>
                    <tr>
                        <td>Восстановление данных (программное) </td>
                        <td>от 100 000 до 200 000 сум
                            (в зависимости от сложности работ)</td>
                    </tr>
                    <tr>
                        <td>Подключение и ввод в эксплуатацию периферийных устройств </td>
                        <td>от 70 000 до 150 000 сум
                            (в зависимости от сложности работ)
                        </td>
                    </tr>
                    <tr>
                        <td>Вызов инженера </td>
                        <td>100 000 сум</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="container mt-3">
            <div class="content">
                <p class="subtitle">
                    Примечания:

                </p>
                <ul style="font-family: Littera Plain;">
                    <li>Стоимость оборудования и ПО в указанные тарифы не входят, а предоставляются самим клиентом.</li>
                    <li>Оплата услуг производится за безналичный расчет в национальной валюте РУз путем заключения договора
                        или по терминалу.</li>
                    <li>Выезд специалиста осуществляется в рабочее время с 9.00 до 18.00 с понедельника по пятницу
                        (кроме праздничных дней) в течение 1 рабочего дня с момента подачи заявки.</li>
                    <li> Дополнительные услуги и работы, не перечисленные в прайс-листе, оплачиваются по договорной
                        стоимости.</li>
                    <li>Выезд специалиста производится в пределах Ташкентской кольцевой автомобильной дороги.</li>
                    <li>При необходимости выезда за пределы кольцевой дороги оплата выезда оговаривается отдельно.</li>
                    <li>В стоимость монтажно-кабельных работ стоимость расходных материалов не включена.</li>
                </ul>
            </div>
        </div>
    </section>
    <ScrollButton @scrollTop="scrollTop"/>
    <UTFooter />
</template>

<script>
import ScrollButton from '@/components/Base/ScrollButton.vue';
import BaseNavbar from '@/components/Base/BaseNavbar.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
export default {
    name: 'SerivesPriceListView',
    components: {
        BaseNavbar,
        UTFooter,
        ScrollButton
    },
    methods: {
        scrollTop() {
            let viewPort = window.scrollY
            if (viewPort > 500) {
                window.scrollTo(0, 0);
                this.isHidden = false
            }

        },
    },
    created() {
        window.scrollTo(0, 0);
    }

}
</script>