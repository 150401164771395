<template>
	<div class="tile is-ancestor">
		<div class="tile is-vertical is-8">
			<div class="tile">
				<div class="tile is-parent is-vertical">
					<article class="tile is-child is-relative">
						<div class="ut-overlay is-overlay"></div>

						<div class="is-flex is-align-items-center is-justify-content-space-between mb-3">
							<div style="z-index: 23;">
								<p class="title has-text-light" style="font-family: RoadRadio; letter-spacing: 0.5px;">Новый
									провайдер <strong>в Ташкенте</strong></p>
								<p class="subtitle has-text-light	">Высокая скорость</p>
							</div>
							<div style="z-index: 23;">
								<div class="has-text-primary is-size-3"><span style="font-weight: 700;">#1</span></div>
							</div>
						</div>
						<ul class="has-text-light is-relative" style="z-index: 23;">
							<li>Обеспечение бесперебойной работы</li>
							<li>Статический <strong class="has-text-light">IP-адрес</strong></li>
						</ul>
					</article>
					<article class="tile is-child is-relative ">
						<div class="ut-overlay is-overlay"></div>

						<div class="is-flex is-align-items-center is-justify-content-space-between mb-3">
							<div style="z-index: 23;">
								<p class="title has-text-light" style="font-family: RoadRadio; letter-spacing: 0.3px;">
									Тех.поддержка 24/7</p>
								<p class="subtitle has-text-light	">Будьте всегда онлайн</p>
							</div>
							<div style="z-index: 23;">
								<div class="has-text-primary is-size-3">
									<span
										style="font-weight: 700; z-index: 23;">
										#2
									</span>
								</div>
							</div>
						</div>
						<ul class="has-text-light is-relative" style="z-index: 23;">
							<li>Колл-центр всегда на связи</li>
							<li>Быстрый и компетентный</li>
						</ul>
					</article>
				</div>
				<div class="tile is-parent">
					<article class="tile is-child is-relative">
						<div class="ut-overlay is-overlay"></div>

						<div class="is-flex is-align-items-center is-justify-content-space-between mb-3">
							<div style="z-index: 23;">
								<p class="title has-text-light" style="font-family: RoadRadio; letter-spacing: 0.5px;">
									Преимущества</p>
								<p class="subtitle has-text-light	">Если не мы то кто?</p>
							</div>
							<div style="z-index: 23;">
								<div class="has-text-primary is-size-3"><span style="font-weight: 700;">#3</span>
								</div>
							</div>
						</div>
						<div class="is-flex is-flex-direction-column is-justify-content-space-between">
							<div class="is-flex is-align-items-flex-start mb-3" style="z-index: 23;">
								<div><font-awesome-icon icon="ethernet" class=" mr-3 has-text-light	" size="lg" /></div>
								<div>
									<p class="title is-6 is-uppercase has-text-light" style="font-family: RoadRadio;">
										стабильное соединение</p>
									<p class="subtitle is-6 has-text-light	">
										Интегратор современных операторских решений, актуальной задачей которых является
										развитие
									</p>
								</div>
							</div>
							<div class="is-flex is-align-items-flex-start mb-3 " style="z-index: 23;">
								<font-awesome-icon icon="question" class=" mr-4 has-text-light	" size="lg" />
								<div>
									<p class="title is-6 is-uppercase has-text-light">Оперативная техподдержка</p>
									<p class="subtitle is-6 has-text-light	">
										Интегратор современных операторских решений, актуальной задачей которых является
										развитие
									</p>
								</div>
							</div>
							<div class="is-flex is-align-items-flex-start" style="z-index: 23;">
								<font-awesome-icon icon="globe" class=" mr-3 has-text-light	" size="lg" />
								<div>
									<p class="title is-6 is-uppercase has-text-light">Подключение от 1 дня</p>
									<p class="subtitle is-6 has-text-light	">
										Интегратор современных операторских решений, актуальной задачей которых является
										развитие
									</p>
								</div>
							</div>
						</div>
					</article>
				</div>
			</div>
			<div class="tile is-parent">
				<article class="tile is-child is-relative">
					<div class="ut-overlay is-overlay"></div>

					<div class="is-flex is-align-items-center is-justify-content-space-between mb-3 is-relative">
						<div style="z-index: 23;">
							<p class="title has-text-light" style="font-family: RoadRadio;">Лояльность к клиентам</p>
							<p class="subtitle has-text-light	">Мы ценим наших клиентов</p>
						</div>
						<div style="z-index: 23;">
							<div class="has-text-primary is-size-3"><span style="font-weight: 700;">#4</span>
							</div>
						</div>
					</div>
				</article>
			</div>
		</div>
		<div class="tile is-parent">
			<article class="tile is-child has-text-light  is-relative">
				<div class="ut-overlay is-overlay"></div>

				<div>
					<div class="is-flex is-align-items-center is-justify-content-space-between mb-3 is-relative">
						<div>
							<p class="title has-text-light"
								style="font-family: RoadRadio; letter-spacing: 0.5px; z-index: 23;">URBAN
								TELECOM</p>
							<p class="subtitle has-text-light">О нас</p>
						</div>
						<div>
							<div class="has-text-primary is-size-3"><span
									style="font-weight: 700; z-index: 23;">#5</span>
							</div>
						</div>
					</div>

					<div class="content is-relative">
						<ul style="list-style-type: none; padding-left: 0; z-index: 23;">
							<li> Мы предоставляем широкополосный доступ к интернет по технологии FTTX на скорости более
								100 Мбит/с и без лимита
							</li>
							<li> <strong class="has-text-light	">Urban Telecom</strong> – это не просто интернет на
								каждый день.</li>
							<li>В первую очередь это партнёрские отношения и помощь в решении ежедневных задач для
								повышения
								эффективности <strong class="has-text-light	">бизнеса</strong>
							</li>
							<li> Мы создаем лучший пользовательский опыт среди всех телекоммуникационных
								компаний на рынке и задаем новые стандарты качества
							</li>
							<li> Выбирая <strong class="has-text-light	">Urban Telecom</strong>, вы получаете <strong
									class="has-text-light	">надежного</strong>
								партнера для вашего
								бизнеса!
							</li>
						</ul>
					</div>
				</div>
			</article>
		</div>
	</div>
</template>

<script>
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
export default {
	name: "Benefits",
	components: {
		FontAwesomeIcon
	}
}
</script>

<style scoped>
.violet {
	border-radius: 10px;
	padding: 10px;
}

article {
	background: url('@/assets/card-bg.jpg') center no-repeat;
	border-radius: 15px;
	padding: 10px;
	object-fit: fill;

}

.ut-overlay {
	background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
	border-radius: 15px;
	padding: 10px;
}
</style>