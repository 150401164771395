<template>
    <router-view />
    
</template>

<style>
@import 'bulma/css/bulma.css';
@import "~@creativebulma/bulma-divider";
@import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";



@font-face {
    font-family: "RoadRadio";
    src: url("@/assets/fonts/RoadRadio/RoadRadio.eot");
    src: local("RoadRadio"), url("@/assets/fonts/RoadRadio/RoadRadio.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/RoadRadio.woff2") format("woff2"), url("@/assets/fonts/RoadRadio/RoadRadio.woff") format("woff"), url("@/assets/fonts/RoadRadio/RoadRadio.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "RoadRadio";
    src: url("@/assets/fonts/RoadRadio/RoadRadio-Light.eot");
    src: local("RoadRadio Light"), local("RoadRadio-Light"), url("@/assets/fonts/RoadRadio/RoadRadio-Light.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/RoadRadio-Light.woff2") format("woff2"), url("@/assets/fonts/RoadRadio/RoadRadio-Light.woff") format("woff"), url("@/assets/fonts/RoadRadio/RoadRadio-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: "RoadRadio";
    src: url("@/assets/fonts/RoadRadio/RoadRadio-Thin.eot");
    src: local("RoadRadio Thin"), local("RoadRadio-Thin"), url("@/assets/fonts/RoadRadio/RoadRadio-Thin.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/RoadRadio-Thin.woff2") format("woff2"), url("@/assets/fonts/RoadRadio/RoadRadio-Thin.woff") format("woff"), url("@/assets/fonts/RoadRadio/RoadRadio-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal
}

@font-face {
    font-family: "RoadRadio";
    src: url("@/assets/fonts/RoadRadio/RoadRadio-Black.eot");
    src: local("RoadRadio Black"), local("RoadRadio-Black"), url("@/assets/fonts/RoadRadio/RoadRadio-Black.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/RoadRadio-Black.woff2") format("woff2"), url("@/assets/fonts/RoadRadio/RoadRadio-Black.woff") format("woff"), url("@/assets/fonts/RoadRadio/RoadRadio-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: "RoadRadio";
    src: url("@/assets/fonts/RoadRadio/RoadRadio-Bold.eot");
    src: local("RoadRadio Bold"), local("RoadRadio-Bold"), url("@/assets/fonts/RoadRadio/RoadRadio-Bold.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/RoadRadio-Bold.woff2") format("woff2"), url("@/assets/fonts/RoadRadio/RoadRadio-Bold.woff") format("woff"), url("@/assets/fonts/RoadRadio/RoadRadio-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: "Littera Plain";
    src: url("@/assets/fonts/RoadRadio/LitteraPlainRegular.eot");
    src: local("Littera Plain Regular"), local("LitteraPlainRegular"), url("@/assets/fonts/RoadRadio/LitteraPlainRegular.eot?#iefix") format("embedded-opentype"), url("@/assets/fonts/RoadRadio/LitteraPlainRegular.woff") format("woff"), url("@/assets/fonts/RoadRadio/LitteraPlainRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

#app {
    min-height:100vh
}

body {
    font-family: Littera Plain;
}

html {
	scroll-behavior: smooth;
}
</style>