<template>
    <div class="columns">
        <div class="column">
            <div class="is-flex">
                <img src="@/assets/icon1.svg" alt="" class="mr-3">
                <span class="ut-top-benefit-icon-title">Стабильное<br> соединение</span>
            </div>
        </div>
		<div class="column">
			<div class="is-flex">
				<img src="@/assets/icon2.svg" alt="" class="mr-3">
				<span class="ut-top-benefit-icon-title">Оперативная <br> техподдержка</span>
			</div>
		</div>
		<div class="column">
			<div class="is-flex">
				<img src="@/assets/icon3.svg" alt="" class="mr-2">
				<div class="ut-top-benefit-icon-title">Подключение от<br> 3 дней</div>
			</div>
		</div>


    </div>
</template>

<script>
    export default {
        name: "TopBenefits"
    }
</script>

<style scoped>
	.ut-top-benefit-icon-title {
		font-family: "RoadRadio";
		font-weight: 700;
		font-size: 30px;
		letter-spacing: 0.5px;
		line-height: 25px;
	}
	
	.ut-top-benefit-icon-subtitle {
		font-weight: 600;
		font-size: 14px;
	}

	@media(max-width: 425px) {
		.ut-top-benefit-icon-title {
		
		font-size: 20px;
	
	   }
	}

	@media(max-width: 375px) {
		.ut-top-benefit-icon-title {
		font-family: "RoadRadio";
		font-weight: 700;
		font-size: 18px;
		letter-spacing: 0.5px;
		line-height: 25px;
	   }
	}
	
	@media(max-width: 320px) {
		.ut-top-benefit-icon-title {
		font-family: "RoadRadio";
		font-weight: 700;
		font-size: 15px;
		letter-spacing: 0.5px;
		line-height: 25px;
	   }
	}
</style>