<template>
	<div class="modal">
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">Заявка на подключение</p>
				<button class="delete" aria-label="close" @click="showConnectModal"></button>
			</header>
			<form @submit.prevent>
				<section class="modal-card-body">

					<div class="field">
						<div class="control has-icons-left">
							<input name="clientName" type="text" class="input" placeholder="Контактное лицо" v-model="clientName" required>
							<span class="icon is-left">
								<font-awesome-icon icon="user" size="sm" />
							</span>
						</div>
					</div>

					<div class="field">
						<div class="control has-icons-left">
							<input name="orgName" type="text" class="input" placeholder="Наименование организации" v-model="clientOrg"
								required>
							<span class="icon is-left">
								<font-awesome-icon icon="fa-solid fa-building" />
							</span>
						</div>
					</div>

					<div class="field">
						<div class="control has-icons-left">
							<input name="legalAddress" type="text" class="input" placeholder="Фактический адрес" v-model="clientAddress"
								required>
							<span class="icon is-left">
								<font-awesome-icon icon="location-dot" />
							</span>
						</div>
					</div>


					<div class="field">
						<div class="control has-icons-left">
							<input name="phoneNumber" type="tel" class="input" placeholder="Контактный номер 909883737" v-model="clientPhone"
								required>
							<span class="icon is-left">
								<font-awesome-icon icon="phone" />
							</span>
						</div>
					</div>


					<div class="field" style="display: none;">
						<div class="control has-icons-left">
							<input name="None" type="tel" class="input" placeholder="Заполните поле" v-model="spamCheck">
						</div>
					</div>

					<div class="field" v-show="isShowWarning">
						<div class="control">
							<p class="subtitle notification is-warning is-light is-7"
								style="font-family: Littera Plain; letter-spacing: 0.5px;">Поля не должны быть пустыми
							</p>
						</div>
					</div>
					<div class="field" v-show="isShowSuccess">
						<div class="control">
							<p class="subtitle notification is-success is-light is-7"
								style="font-family: Littera Plain; letter-spacing: 0.5px;">Заявка отправлена</p>
						</div>
					</div>
					<div class="field" v-show="isShowWarningPhone">
						<div class="control">
							<p class="subtitle notification is-warning is-light is-7"
								style="font-family: Littera Plain; letter-spacing: 0.5px;">Неправильный номер телефона
							</p>
						</div>
					</div>

				</section>
				<footer class="modal-card-foot">
					<button class="button is-link" style="font-family: RoadRadio; letter-spacing: 0.5px;"
						:disabled="isButtonDisabled" @click="clientRequest">Отправить</button>
					<!-- <button class="button is-danger" style="font-family: RoadRadio; letter-spacing: 0.5px;"
						@click="showConnectModal">Отмена</button> -->
				</footer>
			</form>
		</div>
	</div>
</template>

<script>
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

export default {
	name: "ConnectModal",
	components: {
		FontAwesomeIcon,

	},
	data() {
		return {
			isButtonDisabled: false,
			isShowWarningPhone: false,
			isShowSuccess: false,
			isShowWarning: false,
			clientName: '',
			clientOrg: '',
			clientAddress: '',
			clientPhone: '',
			spamCheck: ''
		}
	},
	methods: {
		showConnectModal() {
			this.$emit('showConnectModal')
		},
		clientRequest() {
			let formData
			const phoneNumberRegex = /^\d{2}\d{3}\d{2}\d{2}$/;
			if (this.clientName && this.clientOrg && this.clientAddress && this.clientPhone && !this.spamCheck) {

				if (phoneNumberRegex.test(this.clientPhone)) {
					if (!isNaN(this.clientPhone) && this.clientName.length > 3 && this.clientOrg.length > 3 && this.clientAddress.length > 3) {
						this.isButtonDisabled = true
						this.isShowWarning = false
						this.isShowSuccess = true
						this.isShowWarningPhone = false

						formData = {
							client_name: this.clientName,
							client_org: this.clientOrg,
							client_address: this.clientAddress,
							clinet_phone: this.clientPhone
						}

						this.clientName = ''
						this.clientOrg = ''
						this.clientAddress = ''
						this.clientPhone = ''

						this.$emit('clientRequest', formData)
					} else {
						this.isShowWarning = false
						this.isShowSuccess = false
						this.isShowWarningPhone = true
					}
				}
				else if (!phoneNumberRegex.test(this.clientPhone)) {
					this.isShowWarning = false
					this.isShowSuccess = false
					this.isShowWarningPhone = true
				}


			}
			else if (!this.clientName && !this.clientOrg && !this.clientAddress && !this.clientPhone) {
				this.isShowWarning = true
				this.isShowSuccess = false
			}
			else if (this.clientName && this.clientOrg && this.clientAddress && this.clientPhone && this.spamCheck) {
				this.isShowSuccess = true
				this.isShowWarning = false

				this.clientName = ''
				this.clientOrg = ''
				this.clientAddress = ''
				this.clientPhone = ''
			}

		}
	}
}
</script>

<style scoped>
.modal-card-title {
	font-family: RoadRadio;
	letter-spacing: 0.5px;
}
@media(max-width: 425px) {
	.input{
		font-size: 14px;
	}
	.modal-card-title {
		font-size: 18px;
	}
	.button {
		font-size: 14px;
	}
}
@media(max-width: 375px) {
	.input{
		font-size: 14px;
	}
	.modal-card-title {
		font-size: 18px;
	}
	.button {
		font-size: 14px;
	}
}
@media(max-width: 320px) {
	.input{
		font-size: 14px;
	}
	.modal-card-title {
		font-size: 18px;
	}
	.button {
		font-size: 14px;
	}
}
</style>