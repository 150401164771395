<template>
    <div>
        <div class="container">
            <div class="cards">
                <div class="card ut-overlay">
                    <div class="card-content">
                        <div class=" content has-text-white is-flex is-justify-content-center is-align-items-center">
                            <div class="mr-5">
                                <font-awesome-icon icon="infinity" size="2xl" />
                            </div>
                            <div>
                                <span class="title has-text-white">Безлимитный</span><br>
                                <span style="font-family: Littera Plain;">
                                    Безлимит интернет
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card ut-overlay">
                    <div class="card-content">
                        <div class="content has-text-white">
                            <div class=" content has-text-white is-flex is-justify-content-center is-align-items-center">
                                <div class="mr-5">
                                    <font-awesome-icon icon="gauge-high" size="2xl" />
                                </div>
                                <div>
                                    <span class="title has-text-white">Скорость</span><br>
                                    <span style="font-family: Littera Plain;">
                                        Неограниченная скорость
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card ut-overlay">
                    <div class="card-content">
                        <div class="content has-text-white">
                            <div class=" content has-text-white is-flex is-justify-content-center is-align-items-center">
                                <div class="mr-5">
                                    <font-awesome-icon icon="globe" size="2xl" />
                                </div>
                                <div>
                                    <span class="title has-text-white ">TAS-IX</span><br>
                                    <span class="has-text-centered" style="font-family: Littera Plain;">
                                        Бесплатный доступ
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="container mt-5">
        <div class="column">
            <p class="title is-2 has-text-white">Акции и скидки</p>
            <Carousel :breakpoints="breakpoints" :wrapAround="true">
                <slide class="p-5" :key="1">
                    <div class="stock-card ut-overlay  p-5">
                        <div class="stock-card-header">
                            <p class="title is-2 has-text-white">Скидки до 20% на все тарифные планы</p>
                        </div>
                        <div class="stock-card-body" style="font-family: Littera Plain;">
                            При единовременной оплате
                        </div>
                    </div>

                </slide>
                <slide class="p-5" :key="2">
                    <div class="stock-card ut-overlay p-5">
                        <div class="stock-card-header">
                            <p class="title is-2 has-text-white">Тест-драйв до 5 дней</p>
                        </div>
                        <div class="stock-card-body" style="font-family: Littera Plain;">
                            У вас есть целых 5 дней, чтобы оценить надежность нашего соединения, высокую и стабильную
                            скорость
                        </div>
                    </div>

                </slide>
                <template #addons>
                    <navigation class="has-text-white" />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'


export default {
    name: 'NavbarCards',
    components: {
        FontAwesomeIcon,
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',

            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                300: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                400: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
        }
    }
}
</script>

<style scoped>
.cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ut-overlay {
    background: linear-gradient(179.27deg, #d9d7e073 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
    border-radius: 15px;
}

.card {
    max-width: 400px;
    min-width: 400px;

    margin: 5px;
}

.stock-card {
    min-width: 500px;
    max-width: 800px;

    min-height: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media(max-width: 768px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        min-width: 350px;
        max-width: 350px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }

    .title {
        font-size: 25px;
        font-weight: 900;
    }

    .stock-card {
        min-width: 300px;
        max-width: 300px;
        font-size: 15px;

    }
}

@media(max-width: 375px) {
    .stock-card {
        min-width: 230px;
        max-width: 230px;
        font-size: 15px;

    }
}

@media(max-width: 320px) {
    .card {
        min-width: 250px;
        max-width: 250px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }
    .title {
        font-size: 15px;
        font-weight: 900;
    }
    .stock-card {
        min-width: 200px;
        max-width: 200px;
        font-size: 15px;

    }

}



</style>