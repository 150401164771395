import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdvantagesView from '@/views/AdvantagesVIew.vue'
import VacanciesView from '@/views/VacanciesView.vue'
import TarrifsView from '@/views/TarrifsView.vue'
import CoverageZoneView from '@/views/CoverageZoneView.vue'
import DocumentsView from '@/views/DocumentsView.vue'
import FAQView from '@/views/FAQView.vue'
import PartnersView from '@/views/PartnersView.vue'
import ServicesView from '@/views/ServicesView.vue'
import ServicesPriceListView from '@/views/ServicesPriceListView.vue'
import ServicesIpTransportView from '@/views/ServicesIpTransportView.vue'
import ServicesFiberDesignView from '@/views/ServicesFiberDesignView.vue'
import CRMView from '@/views/CRMView.vue'
import NewsDetailView from '@/views/NewsDetailView.vue'
import PublicOfferView from '@/views/PublicOfferView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/advantages',
    name: 'advantages',
    component: AdvantagesView
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: VacanciesView
  },
  {
    path: '/tarrifs',
    name: 'tarrifs',
    component: TarrifsView
  },
  {
    path: '/coverage-zone',
    name: 'coverage-zone',
    component: CoverageZoneView
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView
  },
  {
    path: '/partners',
    name: 'partners',
    component: PartnersView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/services/price-list',
    name: 'price-list',
    component: ServicesPriceListView
  },
  {
    path: '/services/ip-transport',
    name: 'ip-transport',
    component: ServicesIpTransportView
  },
  {
    path: '/services/fiber-design',
    name: 'fiber-design',
    component: ServicesFiberDesignView
  },
  {
    path: '/services/crm',
    name: 'crm',
    component: CRMView
  },
  {
    path: '/notifications/detail/:type/:id',
    name: 'NewsDetailView',
    component: NewsDetailView
  },
  {
    path: '/public-offer/',
    name: 'public-offer',
    component: PublicOfferView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
