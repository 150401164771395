<template>
	<div>
		<div class="home" v-cloak>
			<div>
				<Navbar />
			</div>

			<section class="section is-medium">
				<div class="container">
					<TopBenefits />
				</div>
			</section>


			<section class="section">
				<div class="container">
					<p class="title" style="font-family: RoadRadio;">Популярные тарифы</p>
					<HomePopularPriceTable @clientRequest="clientRequest" :popularEntityTarrifs="popularEntityTarrifs" :popularIndividualTarrifs="popularIndividualTarrifs" />
				</div>
			</section>

			<section class="section">
				<div class="container">
					<p class="title" style="font-family: RoadRadio;">Будьте в курсе</p>
					<News />
				</div>
			</section>

			<section class="section is-medium">
				<div class="container">
					<p class="title" style="font-family: RoadRadio;">Зона покрытия</p>
					<div class="columns">
						<ConnectForm @clientRequest="clientRequest" />
						<CoverageZone class="is-offset-1" />
					</div>
				</div>
			</section>

			<section class="section is-medium">
				<div class="container">
					<p class="title" style="font-family: RoadRadio;">Партнеры</p>

					<Partners />
				</div>
			</section>

			<div>
				<UTFooter />
			</div>


		</div>
		<ScrollButton @scrollTop="scrollToTop" />
	</div>
</template>

<script>
import ScrollButton from '@/components/Base/ScrollButton.vue'
import CoverageZone from "@/components/Home/CoverageZone.vue"
import TopBenefits from "@/components/Home/TopBenefits.vue"
import Partners from "@/components/Home/Partners.vue"
import HomePopularPriceTable from "@/components/Home/HomePopularPriceTable.vue"
import News from "@/components/Home/News.vue"
import Benefits from "@/components/Home/Benefits.vue"
import Navbar from "@/components/Base/Navbar.vue"
import UTFooter from "@/components/Base/UTFooter.vue"
import ConnectForm from '@/components/Base/ConnectForm.vue'
import axios from "axios"
export default {
	name: 'HomeView',
	components: {
		Navbar,
		Benefits,
		News,
		HomePopularPriceTable,
		UTFooter,
		Partners,
		TopBenefits,
		CoverageZone,
		ScrollButton,
		ConnectForm,
	},
	data() {
		return {
			isShowNextNotificationPage: true,
			isShowLoader: true,
			popularEntityTarrifs: [],
			popularIndividualTarrifs: [],
			geoLocations: [],
			notifications: [],
			isHidden: false,
			viewPort: 0
		}
	},
	methods: {
		getPopularEntityTarrifs() {
			axios
				.get('/api/v1/get-popular-entity-tarrifs/')
				.then(response => {
					this.popularEntityTarrifs = response.data
				})
		},
		getPopularIndividualTarrifs() {
			axios
				.get('/api/v1/get-popular-individual-tarrifs/')
				.then(response => {
					this.popularIndividualTarrifs = response.data
				})
		},
		clientRequest(formData) {
			axios
				.post('/api/v1/connect-message/', formData)
				.then(response => {
				})
		},
		scrollToTop() {
			let viewPort = window.scrollY
			if (viewPort > 500) {
				window.scrollTo(0, 0);
				this.isHidden = false
			}
		},

		getLocations() {
			axios
				.get('/api/v1/geolocations/')
				.then(response => {
					for (let i in response.data) {
						this.geoLocations.push(Object.values(response.data[i]))
					}
				})
		},
		getNotifications() {
			axios
				.get('/api/v1/notifications/')
				.then(response => {
					this.notifications = response.data.results
					if (response.data.next) {
						this.isShowNextNotificationPage = true
					}
					else {
						this.isShowNextNotificationPage = false
					}
				})
		},
		mapInit() {
			let myMap = new ymaps.Map("map", {
				center: [41.311158, 69.279737],
				zoom: 14,
				controls: ['zoomControl']
			});

			let blueCollection = new ymaps.GeoObjectCollection(null, {
				preset: 'islands#blueIcon'
			});

			for (let i in this.geoLocations) {
				let point = this.geoLocations[i]
				let icon = require('@/assets/map-mark.svg')
				blueCollection.add(new ymaps.Placemark(
					point, {}, {
					iconLayout: 'default#image',
					iconImageHref: icon,
					iconImageSize: [30, 42],
				}
				))
			}

			myMap.geoObjects.add(blueCollection);
			myMap.behaviors.disable('scrollZoom');
		},

	},
	mounted() {

		this.getPopularIndividualTarrifs()
		this.getPopularEntityTarrifs()

		this.getLocations()

		// Waiting initializing Yandex-Maps
		// Then passing locations to the function
		
		ymaps.ready(this.mapInit)


	
	}

}
</script>

<style scoped>
.benefits {
	background: -webkit-linear-gradient(90deg, #354dc0, #813ba8, #a52a8a);
	background: linear-gradient(90deg, #354dc0, #813ba8, #a52a8a);
}

.ut-navbar {
	position: relative;
}





@media(max-width: 768px) {

	.title {
		font-size: 25px;
		font-weight: 900;
	}


}

@media(max-width: 425px) {

	.title {
		font-size: 20px;
		font-weight: 700;
	}


}

@media(max-width: 320px) {

	.title {
		font-size: 15px;
		font-weight: 700;
	}


}
</style>