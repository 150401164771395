<template>
    <div class="scroll-button" @click="scrollToTop" style="position:fixed; bottom:40px; right:30px; z-index: 23;">
        <font-awesome-icon icon="arrow-down" rotation=180 style="color: white;"/>
    </div>
</template>


<script>
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
export default {
    name: "ScrollButton",
    components: {
        FontAwesomeIcon
    },
    methods: {
        scrollToTop() {
            this.$emit('scrollTop')
        }
    }
}
</script>


<style scoped>
.scroll-button {
    min-width: 65px;
    max-width: 65px;

    min-height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    border-radius: 10px;
    background-color: #243691fa;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.scroll-button:hover {
    background-color: #2a3faab6;
}


@media(max-width: 768px) {
    .scroll-button {
        min-width: 50px;
        max-width: 50px;

        min-height: 50px;
    }
}
</style>