<template>
    <div class="column">
        <div class="container">
            <img src="@/assets/partners/iftg.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/ssd.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/metro.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/qiwi.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/sharq.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/allplay.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/tvcom.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/megogo.png" alt="" class="ut-partners-img">
            <img src="@/assets/partners/spectr-it.png" alt="" class="ut-partners-img">
        </div>


    </div>
</template>

<script>
export default {
    name: "Partners"
}
</script>

<style>
.ut-partners-img {
    width: auto;
    margin: 20px 40px;
    max-height: 90px;
    filter: grayscale(100%);
    opacity: 0.5;
}

@media (max-width: 1100px) {
    .ut-partners-img {
        margin: 12px 10px;
        max-width: 92px;
    }
}
</style>