<template>
    <div class="">
        <p class="title" style="font-family: RoadRadio;">Физическим лицам</p>
        <p class="subtitle" style="font-family: Littera Plain;">Все для дома и ничего лишнего</p>
        <Carousel :breakpoints="breakpoints">
            <slide v-for="tarrif in individualTarrifs" :key="tarrif.id" class="p-5">

                <div class="ut-tarrif-card is-relative">
                    <div class="ut-overlay is-overlay"></div>
                    <div class="ut-tarrif-card-header is-relative mb-5">
                        <div class="has-text-white ut-tarrif-card-header">
                            {{ tarrif.tarrif_name }}
                        </div>
                    </div>
                    <div class="ut-tariif-card-tags is-relative tags mb-5">
                        <div class="tag is-primary">FTTX</div>
                        <div class="tag is-primary">UNLIM</div>
                        <div class="tag is-primary">TAS-IX</div>
                    </div>
                    <div class="is-flex is-relative is-align-items-flex-start is-align-items-center mb-5">
                        <div class="mr-3">
                            <font-awesome-icon icon="sun" size="xl" />
                        </div>
                        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                            <span class="ut-tariif-card-inner-desc-top">
                                {{ tarrif.tarrif_day_speed }} Мб/с
                            </span>
                            <span class="ut-tariif-card-inner-desc-bottom">Скорость днем</span>
                        </div>
                    </div>
                    <div class="is-flex is-align-items-flex-start is-align-items-center is-relative mb-5">
                        <div class="mr-3">
                            <font-awesome-icon icon="moon" size="xl" />
                        </div>
                        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                            <span class="ut-tariif-card-inner-desc-top">
                                {{ tarrif.tarrif_night_speed }} Мб/с
                            </span>
                            <span class="ut-tariif-card-inner-desc-bottom">Скорость ночью</span>
                        </div>
                    </div>
                    <div class="is-flex is-align-items-flex-start is-align-items-center is-relative mb-5">
                        <div class="mr-3">
                            <font-awesome-icon icon="money-bill-1" size="xl" />
                        </div>
                        <div class="is-flex is-flex-direction-column">
                            <span class="ut-tariif-card-inner-desc-top">
                                {{ tarrif.tarrif_price }} сум
                            </span>
                        </div>
                    </div>
                    <div>
                        <button class="ut-tarrif-card-button" @click="showConnectModal">Подключить</button>
                    </div>
                </div>
            </slide>
            <template #addons>
                <navigation  class="is-hidden-mobile"/>
            </template>
        </Carousel>
        <ConnectModal @showConnectModal="showConnectModal" @clientRequest="clientRequest"
        :class="{ 'is-active': isShowConectModal }" />
    </div>
   
</template>

<script>
import axios from 'axios'
import ConnectModal from '@/components/Home/ConnectModal.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

export default {
    name: "AllTarrifs",
    components: {
        Carousel,
        Slide,
        Navigation,
        FontAwesomeIcon,
        ConnectModal
    },
    emits: ['clientRequest'],
    props: {
        individualTarrifs: Array,
        entityTarrifs: Array
    },
    data() {
        return {
            isShowConectModal: false,
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                300: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                400: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center'
                },
            },
        }
    },
    methods: {
        showConnectModal() {
            if (this.isShowConectModal === false) {
                this.isShowConectModal = true
            }
            else {
                this.isShowConectModal = false
            }
        },
        clientRequest(formData) {
            this.$emit('clientRequest', formData)
        },

    },
}
</script>

<style scoped>
.ut-tarrif-card {
    border-radius: 15px;
    background-image: url("../../assets/night.jpg");
    font-family: "RoadRadio";
    color: white;
    padding: 30px;
    min-height: 500px;
    min-width: 300px;
    transition: all 0.5s ease;
    margin: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

}



.ut-overlay {
    background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
    border-radius: 15px;
}

.ut-tariif-card-inner-desc-bottom {
    font-family: "Littera Plain";
    font-weight: 500;
    font-size: 15px;
    color: darkgrey;
}

.ut-tariif-card-inner-desc-top {
    font-size: 20px;
    line-height: 20px;

}

.ut-tarrif-card-button {
    border-radius: 15px;
    border: none;
    background: rgba(112, 43, 192, 0.1);
    padding: 15px;
    color: white;
    position: relative;
    font-family: "RoadRadio";
    font-size: 17px;
    letter-spacing: 1px;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease;
}

.ut-tarrif-card-button:hover {
    background: rgba(52, 20, 99, 0.1);
    text-decoration: underline;

}

.tag {
    letter-spacing: 0.2px;
    font-weight: 900;
    font-size: 12px;
}

.ut-tarrif-card-header {
    font-size: 30px;
}

@media(max-width: 320px) {
    .ut-tarrif-card {
        min-width: 250px;
        max-width: 250px;

        padding: 10px;

        font-size: 14px;
    }

    .ut-tariif-card-inner-desc-top {
        font-size: 14px;
    }

    .ut-tariif-card-inner-desc-bottom {
        font-size: 14px;
    }

    .ut-tarrif-card-header{
        font-size: 20px;
    }

    .tag {
        font-size: 10px;
    }

    .ut-tarrif-card-button {
        font-size: 14px;
    }
}
</style>