<template>
	<footer class="footer has-text-white p-5">
		<div class="columns">
			<div class="column is-flex is-flex-direction-column is-align-items-center">
				<div>
					<img src="@/assets/logo.svg" alt="">
				</div>
				<div class="is-flex is-justify-content-space-around mt-3">
					<img src="@/assets/telegram.png" class="mr-4 is-clickable" alt="telegram">
					<img src="@/assets/instagram.png" class="mr-4 is-clickable" alt="instagram">
					<img src="@/assets/facebook.png" class="is-clickable" alt="facebook">
				</div>
				<div class="mt-3">
					<a href="https://gis.uz"><img src="@/assets/gis.png" alt=""></a>
				</div>
			</div>
			<div class="column is-flex is-flex-direction-column">
				<div class="is-flex is-flex-direction-column mb-5">
					<h5 class="title is-5 has-text-white" style="font-family: RoadRadio; letter-spacing: 0.5px;">Контакты
					</h5>
					<span style="font-weight: 600;">Служба поддержки</span>
					<a name="contacts" href="tel:+998788882888" class="mt-2 ut-footer-number-link">+998 (78) 888 28 88</a>
				</div>
				<div class="is-flex is-flex-direction-column">
					<span style="font-weight: 600;">Колл-центр</span>
					<a href="tel:+998788882888" class="mt-2 ut-footer-number-link">+998 (78) 888 28 88</a>
				</div>


			</div>
			<div class="column is-flex is-flex-direction-column">
				<h5 class="title is-5 has-text-white" style="font-family: RoadRadio; letter-spacing: 0.5px;">О компании</h5>
				<router-link :to="{ name: 'advantages' }"
					class="ut-footer-number-link"><span>Преимущества</span></router-link>
				<router-link :to="{ name: 'vacancies' }" class="ut-footer-number-link"><span>Вакансии</span> </router-link>
				<router-link :to="{ name: 'public-offer'}" class="ut-footer-number-link"><span>Оферта</span></router-link>
			</div>
			<div class="column is-flex is-flex-direction-column">
				<h5 class="title is-5 has-text-white" style="font-family: RoadRadio; letter-spacing: 0.5px;">Интернет</h5>
				<router-link :to="{ name: 'tarrifs' }" class="ut-footer-number-link"><span>Тарифы</span></router-link>
				<router-link :to="{ name: 'documents' }" class="ut-footer-number-link"><span>Документы</span></router-link>
				<router-link :to="{ name: 'coverage-zone' }" class="ut-footer-number-link"><span>Зона
						покрытия</span></router-link>

			</div>
			<div class="column is-flex is-flex-direction-column">
				<h5 class="title is-5 has-text-white" style="font-family: RoadRadio; letter-spacing: 0.5px;">Справка</h5>
				<router-link :to="{ name: 'faq' }" class="ut-footer-number-link"><span>Частые вопросы</span></router-link>
			</div>

		</div>
		<hr style="background-color: grey;">
		<div class="subtitle has-text-white" style="font-size: 16px; letter-spacing: 1px; font-family: RoadRadio;">
			<span class="p-0 m-0">OOO "INDEPENDENT TELECOM INOVATIONS" 2023 ©</span>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
.footer {
	flex-shrink: 0;
	background-color: #271856;
}

.ut-footer-number-link {
	font-family: Littera Plain;
	text-decoration: none;
	color: white
}

.ut-footer-number-link:hover {
	text-decoration: underline;
	transition: text-decoration 1s ease;

}

@media(max-width: 320px) {
	footer {
		font-size: 14px;
	}	
}
</style>