<template>
	<div class="m-4">
		<BaseNavbar />
	</div>
	<section class="section">
		<div class="container">
			<p class="title" style="font-family: RoadRadio;">Наши партнеры</p>
			<p class="subtitle" style="font-family: RoadRadio;">Часть нас</p>
			<partners />
		</div>
	</section>
	<UTFooter />
</template>

<script>
import BaseNavbar from "@/components/Base/BaseNavbar.vue"
import UTFooter from "@/components/Base/UTFooter.vue"
import Partners from "@/components/Home/Partners.vue"
export default {
	name: "PartnersView",
	components: {
		BaseNavbar,
		UTFooter,
		Partners
	}
}
</script>

<style scoped>
@media(max-width: 320px) {
	.title {
		font-size: 14px;
	}
	.subtitle {
		font-size: 12px;
	}
}
</style>