<template>
    <div>
        <div class="m-4">
            <BaseNavbar />
        </div>
        <section class="section mt-5 mb-5">
            <div class="container">
                <p class="title" style="font-family: RoadRadio;">Зона покрытия</p>
                <div class="columns">
                    <ConnectForm @clientRequest="clientRequest" />
                    <CoverageZone class="is-offset-1" />
                </div>
            </div>
        </section>
        <UTFooter />
    </div>
</template>

<script>
import ConnectForm from '@/components/Base/ConnectForm.vue';
import BaseNavbar from '@/components/Base/BaseNavbar.vue';
import CoverageZone from '@/components/Home/CoverageZone.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import axios from 'axios';
export default {
    name: "CoverageZoneView",
    components: {
        BaseNavbar,
        UTFooter,
        CoverageZone,
        ConnectForm
    },
    data() {
        return {
            geoLocations: []
        }
    },
    methods: {
        clientRequest(formData) {
            axios
                .post('/api/v1/send-message/', formData)
                .then(response => {
                    console.log(response);
                })
        },
        mapInit() {
			let myMap = new ymaps.Map("map", {
				center: [41.311158, 69.279737],
				zoom: 14,
				controls: ['zoomControl']
			});

			let blueCollection = new ymaps.GeoObjectCollection(null, {
				preset: 'islands#blueIcon'
			});

			for (let i in this.geoLocations) {
				let point = this.geoLocations[i]
				let icon = require('@/assets/map-mark.svg')
				blueCollection.add(new ymaps.Placemark(
					point, {}, {
					iconLayout: 'default#image',
					iconImageHref: icon,
					iconImageSize: [30, 42],
				}
				))
			}

			myMap.geoObjects.add(blueCollection);
			myMap.behaviors.disable('scrollZoom');
		},
        getLocations() {
			axios
				.get('/api/v1/geolocations/')
				.then(response => {
					for (let i in response.data) {
						this.geoLocations.push(Object.values(response.data[i]))
					}
				})
		},
    },
    mounted() {
        this.getLocations()

		// Waiting initializing Yandex-Maps
		// Then passing locations to the function
		ymaps.ready(this.mapInit)
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>


<style scoped>
@media(max-width: 425px) {
    .title {
        font-size: 25px;
    }
}

@media(max-width: 375px) {
    .navbar-item {
        font-size: 25px;
    }
}

@media(max-width: 320px) {
    .navbar-item {
        font-size: 25px;
    }
}
</style>