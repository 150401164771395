<template>
    <div>
        <form @submit.prevent>
            <div class="ut-connect-form box">
                <p class="title is-6" style="font-family: RoadRadio; letter-spacing: 1px;">Выберите адрес подключения</p>

                <div class="field">
                    <div class="control has-icons-left">
                        <input name="userName" type="text" class="input" placeholder="Контактное лицо" v-model="clientName" required>
                        <span class="icon is-left">
                            <font-awesome-icon icon="user" size="sm" />
                        </span>
                    </div>
                </div>

                <div class="field">
                    <div class="control has-icons-left">
                        <input name="orgName" type="text" class="input" placeholder="Наименование организации" v-model="clientOrg"
                            required>
                        <span class="icon is-left">
                            <font-awesome-icon icon="fa-solid fa-building" />
                        </span>
                    </div>
                </div>

                <div class="field">
                    <div class="control has-icons-left">
                        <input name="legalAddress" type="text" class="input" placeholder="Фактический адрес" v-model="clientAddress" required>
                        <span class="icon is-left">
                            <font-awesome-icon icon="location-dot" />
                        </span>
                    </div>
                </div>


                <div class="field">
                    <div class="control has-icons-left">
                        <input name="phoneNumber" type="tel" class="input" placeholder="Ваш номер телефона" v-model="clientPhone" required>
                        <span class="icon is-left">
                            <font-awesome-icon icon="phone" />
                        </span>
                    </div>
                </div>

                <div class="field" style="display: none;">
                    <div class="control has-icons-left">
                        <input name="None" type="tel" class="input" placeholder="Заполните поле" v-model="spamCheck">
                    </div>
                </div>

                <div class="field" v-show="isShowWarning">
                    <div class="control">
                        <p class="subtitle notification is-warning is-light is-7"
                            style="font-family: Littera Plain; letter-spacing: 0.5px;">Поля не должны быть пустыми</p>
                    </div>
                </div>
                <div class="field" v-show="isShowSuccess">
                    <div class="control">
                        <p class="subtitle notification is-success is-light is-7"
                            style="font-family: Littera Plain; letter-spacing: 0.5px;">Заявка отправлена</p>
                    </div>
                </div>
                <div class="field" v-show="isShowWarningPhone">
                    <div class="control">
                        <p class="subtitle notification is-warning is-light is-7"
                            style="font-family: Littera Plain; letter-spacing: 0.5px;">Неправильный номер телефона</p>
                    </div>
                </div>
                <button class="button is-fullwidth is-link" :disabled="isButtonDisabled" style="font-family: RoadRadio;"
                    @click="clientRequest">Подключиться</button>
            </div>
        </form>
    </div>
</template>

<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
export default {
    name: "ConnectForm",
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            isButtonDisabled: false,
            isShowWarningPhone: false,
            isShowSuccess: false,
            isShowWarning: false,
            clientName: '',
            clientOrg: '',
            clientAddress: '',
            clientPhone: '',
            spamCheck: '',

        }
    },
    methods: {
        clientRequest() {
            let formData;
            const phoneNumberRegex = /^\d{2}\d{3}\d{2}\d{2}$/;
            if (this.clientName && this.clientOrg && this.clientAddress && this.clientPhone && !this.spamCheck) {
                if (phoneNumberRegex.test(this.clientPhone)) {
                    if (!isNaN(this.clientPhone) && this.clientName.length > 3 && this.clientOrg.length > 3 && this.clientAddress.length > 3) {
                        this.isButtonDisabled = true
                        this.isShowWarningPhone = false
                        this.isShowWarning = false
                        this.isShowSuccess = true

                        formData = {
                            client_name: this.clientName,
                            client_org: this.clientOrg,
                            client_address: this.clientAddress,
                            clinet_phone: this.clientPhone
                        }

                        this.clientName = ''
                        this.clientOrg = ''
                        this.clientAddress = ''
                        this.clientPhone = ''

                        this.$emit('clientRequest', formData)
                    }
                    else {
                        this.isShowWarning = false
                        this.isShowSuccess = false
                        this.isShowWarningPhone = true
                    }
                }
                else if (!phoneNumberRegex.test(this.clientPhone)) {
                    this.isShowWarning = false
                    this.isShowSuccess = false
                    this.isShowWarningPhone = true
                }

            }
            else if (!this.clientName && !this.clientOrg && !this.clientAddress && !this.clientPhone) {
                this.isShowSuccess = false
                this.isShowWarning = true

                this.clientName = ''
                this.clientOrg = ''
                this.clientAddress = ''
                this.clientPhone = ''
            }
            else if (this.clientName && this.clientOrg && this.clientAddress && this.clientPhone && this.spamCheck) {
                this.isShowSuccess = true
                this.isShowWarning = false

                this.clientName = ''
                this.clientOrg = ''
                this.clientAddress = ''
                this.clientPhone = ''
            }

        }
    }
}
</script>


<style scoped>
@media(max-width: 320px) {
    .input {
        font-size: 14px;
    }

    .button {
        font-size: 14px;
        letter-spacing: 0.5px;
    }
}
</style>