<template>
    <div class="m-4">
        <BaseNavbar />
    </div>

    <section class="section is-fullheight mt-5 mb-5" style="font-family: Littera Plain;">
        <div class="container" style="min-height:200px">
            <p class="title" style="font-family: RoadRadio;">Частые вопросы</p>
            <p class="subtitle" style="font-family: RoadRadio;">Все ответы здесь</p>
            <div class="tabs is-left">
                <ul>
                    <li :class="{ 'is-active': isShowEntity }" @click="selectTab($event.target.id)"><a
                            id="faq-org">Юридическим лицам</a></li>
                    <li :class="{ 'is-active': isShowSetup }" @click="selectTab($event.target.id)"><a id="setup">Настройка
                            роутера</a></li>
                </ul>
            </div>
            <div class="tile is-ancestor" v-show="isShowEntity">
                <div class="tile is-vertical is-8">
                    <div class="tile">
                        <div class="tile is-parent is-vertical">
                            <article class="tile is-child ">
                                <article class="message is-info">
                                    <div class="message-header">
                                        <p>Несу ли я ответственность за Home Ethernet кабель?</p>
                                    </div>
                                    <div class="message-body">
                                        Клиент несет ответственность за часть кабеля Home Ethernet, которая находится внутри
                                        помещения клиента.
                                    </div>
                                </article>
                            </article>
                            <article class="tile is-child">
                                <article class="message is-info">
                                    <div class="message-header">
                                        <p>Как я могу сменить тарифный план?</p>
                                    </div>
                                    <div class="message-body">
                                        Вам могут помочь в этом вопросе менеджеры Офиса продаж, либо операторы Call-центра.
                                    </div>
                                </article>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child">
                                <article class="message is-info">
                                    <div class="message-header">
                                        <p>Я не помню пароль от Персонального Кабинета. Как выйти из положения?</p>
                                    </div>
                                    <div class="message-body">
                                        Изначально логин и пароль от Персонально Кабинета выдаётся в памятке пользователя
                                        (приложение к Вашему
                                        контракту).<br>
                                        Если же Вы потеряли контракт и забыли пароль от Кабинета, то владельцу
                                        контракта необходимо
                                        будет обратиться в колл-центр для восстановления пароля.
                                    </div>
                                </article>
                            </article>
                        </div>
                    </div>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child">
                        <article class="message is-info">
                            <div class="message-header">
                                <p>Какие адреса у DNS-серверов Urban Telecom?</p>
                            </div>
                            <div class="message-body">
                                Предпочитаемый DNS-сервер: 212.115.115.1 <br>
                                Альтернативный DNS-сервер: 212.115.115.2
                            </div>
                        </article>
                    </article>
                </div>
            </div>


            <div v-show="isShowSetup">
                <div class="field">
                    <div class="control">
                        <div class="select">
                            <select name="whoareyou" v-model="clientType">
                                <option selected disabled>Выберите Юр/Физ лицо</option>
                                <option value="entity">Юридическое</option>
                                <option value="individual">Физическое</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <div class="select">
                            <select name="select-router" v-model="clientRouter" :disabled="clientType=='Выберите Юр/Физ лицо'">
                                <option selected disabled>Выберите роутер</option>
                                <option value="tplink940n">Tp-link 940N</option>
                                <option value="tplinkarcherc20">Tp-link Archer C20</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Entity tp-link 940n setup -->
                <div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n'" >
                        <header class="card-header">
                            <p class="card-header-title"># 1 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nlogin.jpg" width="200px" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Логин и пароль</strong> - по умолчанию логин и пароль admin.
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n' && clientType=='entity'">
                        <header class="card-header">
                            <p class="card-header-title"># 2 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nnetwork.jpg" alt="setup-network">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>IP-адресс, Маска подсети, Основной шлюз</strong> - выдается абоненту при заключении договора.
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n' && clientType=='individual'">
                        <header class="card-header">
                            <p class="card-header-title"># 2 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nstatic.jpg" alt="setup-network">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>PPPoe(логин и пароль)</strong> - выдается абоненту при заключении договора.
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n'">
                        <header class="card-header">
                            <p class="card-header-title"># 3 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nwifissid.jpg" alt="setup-ssid">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Имя сети</strong> - укажите любое имя для вашей сети.
                                <br>
                                <i>*имя сети может быть указано как SSID</i>
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n'">
                        <header class="card-header">
                            <p class="card-header-title"># 4 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nwifipassword.jpg" alt="setup-password">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль PSK</strong> - укажите ваш пароль.
                                <br>
                                <i>*Пароль должен быть не меньше 8 символов</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>

                                
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplink940n'">
                        <header class="card-header">
                            <p class="card-header-title"># 5 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nWPS.jpg" alt="setup-wps">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль WPS</strong> - обязательно выключите эту функцию.
                                <br>
                                <i>*WPS может быть указан как QSS</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Entity tp-link 940n setup -->

                <!-- Entity tp-link archer c20 setup -->
                <div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'" >
                        <header class="card-header">
                            <p class="card-header-title"># 1 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplink940nlogin.jpg" width="200px" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Логин и пароль</strong> - по умолчанию логин и пароль admin.
                            </div>
                        </div>
                    </div>

                    <div class="card m-1"  v-show="clientRouter=='tplinkarcherc20' && clientType=='entity'">
                        <header class="card-header">
                            <p class="card-header-title"># 2 Шаг</p>
                           
                        </header>
                        <div class="card-image" >
                            <figure class="image">
                                <img src="@/assets/tplinkc20static.jpg" alt="setup-network">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>IP-адресс, Маска подсети, Основной шлюз</strong> - выдается абоненту при заключении договора.
                            </div>
                        </div>
                    </div>

                    <div class="card m-1"  v-show="clientRouter=='tplinkarcherc20' && clientType=='individual'" >
                        <header class="card-header">
                            <p class="card-header-title"># 2 Шаг</p>
                           
                        </header>
                        <div class="card-image" >
                            <figure class="image">
                                <img src="@/assets/tplinkc20pppoe.jpg" alt="setup-network2" >
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>PPPoe(логин и пароль)</strong> - выдается абоненту при заключении договора.
                            </div>
                        </div>
                    </div>


                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 3 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wifi1.jpg" alt="setup-ssid">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Имя сети</strong> - укажите любое имя для вашей сети.
                                <br>
                                <i>*имя сети может быть указано как SSID</i>
                                <br>
                                <i>*В конце имени добавьте префикс ввиде _2.4G/2.4G</i>
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 4 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wifipassword1.jpg" alt="setup-password">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль PSK</strong> - укажите ваш пароль.
                                <br>
                                <i>*Пароль должен быть не меньше 8 символов</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>

                                
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 5 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wifiwps1.jpg" alt="setup-wps">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль WPS</strong> - обязательно выключите эту функцию.
                                <br>
                                <i>*WPS может быть указан как QSS</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 6 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wifi2.jpg" alt="setup-ssid">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Имя сети</strong> - укажите любое имя для вашей сети.
                                <br>
                                <i>*имя сети может быть указано как SSID</i>
                                <br>
                                <i>*В конце имени добавьте префикс ввиде _5G/5G</i>
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 7 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wifipassword2.jpg" alt="setup-password">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль PSK</strong> - укажите ваш пароль.
                                <br>
                                <i>*Пароль должен быть не меньше 8 символов</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>

                                
                            </div>
                        </div>
                    </div>
                    <div class="card m-1" v-show="clientRouter=='tplinkarcherc20'">
                        <header class="card-header">
                            <p class="card-header-title"># 8 Шаг</p>
                           
                        </header>
                        <div class="card-image">
                            <figure class="image">
                                <img src="@/assets/tplinkc20wps2.jpg" alt="setup-wps">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <strong>Пароль WPS</strong> - обязательно выключите эту функцию.
                                <br>
                                <i>*WPS может быть указан как QSS</i>
                                <br>
                                <i>*сохраните вашу настройку в конце страницы</i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>


    <UTFooter />
</template>

<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import BaseNavbar from '@/components/Base/BaseNavbar.vue'
import UTFooter from '@/components/Base/UTFooter.vue';
export default {
    name: "FAQView",
    components: {
        BaseNavbar,
        UTFooter,
        FontAwesomeIcon
    },
    data() {
        return {
            isShowEntity: true,
            isShowSetup: false,
            clientRouter: 'Выберите роутер',
            clientType: 'Выберите Юр/Физ лицо'
        }
    },
    methods: {
        selectTab(tabId) {
            console.log(tabId);
            if (tabId == 'faq-org') {
                this.isShowEntity = true
                this.isShowSetup = false
            }
            else if (tabId == 'setup') {
                this.isShowSetup = true
                this.isShowEntity = false
            }
        }
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>