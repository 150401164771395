<template>
    <div>
        <p class="title is-4" style="font-family: RoadRadio;">Оставьте заявку</p>
        <form @submit.prevent>
            <div class="field">
                <div class="control has-icons-left">
                    <input type="text" class="input" placeholder="Контактное лицо" v-model="clientName" required>
                    <span class="icon is-left">
                        <font-awesome-icon icon="user" size="sm" />
                    </span>
                </div>
            </div>
            <div class="field">
                <div class="control has-icons-left">
                    <input type="text" class="input" placeholder="Наименование организации" v-model="clientOrg" required>
                    <span class="icon is-left">
                        <font-awesome-icon icon="fa-solid fa-building" size="sm" />
                    </span>
                </div>
            </div>
            <div class="field">
                <div class="control has-icons-left">
                    <input type="text" class="input" placeholder="Номер телефона" v-model="clientPhone" required>
                    <span class="icon is-left">
                        <font-awesome-icon icon="phone" size="sm" />
                    </span>
                </div>
            </div>
            <div class="field" style="display: none;">
                <div class="control has-icons-left">
                    <input type="text" class="input" placeholder="Заполните поле" v-model="spamCheck">
                </div>
            </div>
            <div class="field" v-show="isShowWarning">
                <div class="control">
                    <p class="subtitle notification is-warning is-light is-7"
                        style="font-family: Littera Plain; letter-spacing: 0.5px;">Поля не должны быть пустыми</p>
                </div>
            </div>
            <div class="field" v-show="isShowSuccess">
                <div class="control">
                    <p class="subtitle notification is-success is-light is-7"
                        style="font-family: Littera Plain; letter-spacing: 0.5px;">Заявка отправлена</p>
                </div>
            </div>
            <div class="field" v-show="isShowWarningPhone">
                <div class="control">
                    <p class="subtitle notification is-warning is-light is-7"
                        style="font-family: Littera Plain; letter-spacing: 0.5px;">Неправильный номер телефона</p>
                </div>
            </div>
            <button class="button is-fullwidth is-link" :disabled="isButtonDisabled"
                @click="clientRequest" style="font-family: RoadRadio; letter-spacing: 0.5px;">Отправить</button>
        </form>
    </div>
</template>

<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';
export default {
    name: 'ServicesForm',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            spamCheck: '',
            isButtonDisabled: false,
            isShowWarningPhone: false,
            clientName: '',
            clientOrg: '',
            clientPhone: '',
            isShowWarning: false,
            isShowSuccess: false,
        }
    },
    methods: {
        clientRequest() {
            let formData
            if (this.clientName && this.clientOrg && this.clientPhone && !this.spamCheck) {
                if (!isNaN(this.clientPhone) && this.clientName.length > 3 && this.clientOrg.length > 3) {
                    this.isButtonDisabled = true
                    this.isShowWarning = false
                    this.isShowSuccess = true

                    formData = {
                        client_name: this.clientName,
                        client_org: this.clientOrg,
                        clinet_phone: this.clientPhone
                    }

                    this.clientName = ''
                    this.clientOrg = ''
                    this.clientPhone = ''

                    this.$emit('clientRequest', formData)
                }
                else {
                    this.isShowWarning = false
                    this.isShowSuccess = false
                    this.isShowWarningPhone = true
                }


            } else {
                this.isShowWarningPhone = false
                this.isShowSuccess = false
                this.isShowWarning = true
            }

        }
    }
}
</script>