<template>
	<div class="column box">
		<div id="map" style="width: 100%; height: 400px;"></div>
	</div>
</template>

<script>

import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import ConnectForm from '@/components/Base/ConnectForm.vue'

export default {
	name: "CoverageZone",
	props: {
		geoLocations: {
			type: Array,
			required: false
		}
	},
	components: {
		FontAwesomeIcon
	},
	data() {
		return {

			city: 'Выберите город',
			district: 'Выберите район',
			address: '',

		}
	},
	
}
</script>

<style scoped>
.button {
	background-color: #702bc0;
	color: white;
}

.button:hover {
	background-color: #4d1e86;
	color: white;
	transition: all 0.5s ease;
}
</style>