<template>
    <section class="hero is-medium is-relative has-text-white box">
        <BaseNavbarBg>
            <template v-slot:title>
                <p class="ut-title has-text-white has-text-centered">Технические услуги</p>
            </template>
            <template v-slot:content>
                <ServicesNavbarCards>
                    <template v-slot:first-title>
                        Интеграция
                    </template>
                    <template v-slot:first-subtitle>
                        Надёжная и быстрая
                    </template>
                    <template v-slot:second-title>
                        Скорость
                    </template>
                    <template v-slot:second-subtitle>
                        Сделаем в короткие сроки
                    </template>
                    <template v-slot:third-title>
                        Все в одном
                    </template>
                    <template v-slot:third-subtitle>
                        Интернет и услуги
                    </template>

                </ServicesNavbarCards>


                <BaseCarousel>
                    <template v-slot:carousel-title>
                        Акции и скидки
                    </template>
                    <template v-slot:carousel-first-description-title>
                        Тех. обслуживание
                    </template>
                    <template v-slot:carousel-first-description-subtitle>
                        Все виды услуг в одном прайс-листе
                    </template>
                    <template v-slot:carousel-second-description-title>
                        Строительство и проектирование
                    </template>
                    <template v-slot:carousel-second-description-subtitle>
                        Качественно и быстров в срок
                    </template>
                </BaseCarousel>
            </template>
        </BaseNavbarBg>
    </section>

    <section class="section is-medium is-hidden-mobile">
        <div class="container">
            <ServicesTopBenefits />
        </div>
    </section>

    <section class="section">
        <div class="container">
            <p class="ut-title" style="font-family: RoadRadio;">Наши услуги</p>
            <p class="subtitle" style="font-family: RoadRadio">Все включено</p>
            <div class="columns">
                <div class="column">
                    <div class="tech-card box notification is-link is-light">
                        <div class="tech-card-title">
                            <p class="title" style="font-family: RoadRadio;">
                                Тех.обслуживание
                            </p>
                            <p class="subtitle" style="font-family: Littera Plain; color: #a19f9f;">
                                Прайс-лист
                            </p>
                        </div>
                        <div class="tech-card-footer mt-4">
                            <div class="button is-info" @click="this.$router.push({ name: 'price-list' })"
                                style="font-family: RoadRadio; letter-spacing: 1px;">Подробнее</div>
                        </div>
                    </div>
                </div>
                <div class="column" style="font-family: Littera Plain">

                    <div class="tech-card box notification is-link is-light">
                        <div class="tech-card-title">
                            <p class="title" style="font-family: RoadRadio;">
                                Внедрение CRM
                            </p>
                            <p class="subtitle" style="font-family: Littera Plain; color: #a19f9f;">
                                Взаимотношение бизнеса и клиента </p>
                        </div>
                        <div class="tech-card-footer mt-4">
                            <div @click="this.$router.push({ name: 'crm' })" class="button is-info" style="font-family: RoadRadio; letter-spacing: 1px;">Подробнее</div>
                        </div>
                    </div>

                </div>


            </div>

            <div class="columns">
                <div class="column" style="font-family: Littera Plain">

                    <div class="tech-card box notification is-link is-light">
                        <div class="tech-card-title">
                            <p class="title" style="font-family: RoadRadio;">
                                IP-транспорт
                            </p>
                            <p class="subtitle" style="font-family: Littera Plain; color: #a19f9f;">
                                Аренда ip-транспорта </p>
                        </div>
                        <div class="tech-card-footer mt-4">
                            <div class="button is-info" @click="this.$router.push({ name: 'ip-transport' })"
                                style="font-family: RoadRadio; letter-spacing: 1px;">
                                Подробнее
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column" style="font-family: Littera Plain">

                    <div class="tech-card box notification is-link is-light">
                        <div class="tech-card-title">
                            <p class="title" style="font-family: RoadRadio;">
                                ВОЛС
                            </p>
                            <p class="subtitle" style="font-family: Littera Plain; color: #a19f9f;">
                                Проектирование и прокладка транспорта </p>
                        </div>
                        <div class="tech-card-footer mt-4">
                            <div class="button is-info" @click="this.$router.push({ name: 'fiber-design' })"
                                style="font-family: RoadRadio; letter-spacing: 1px;">Подробнее</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section is-medium">
        <div class="container">
            <p class="ut-title" style="font-family: RoadRadio;">Развивайтесь вместе с нами</p>
            <p class="subtitle" style="font-family: RoadRadio">Win to win</p>
            <div class="columns">
                <div class="column is-hidden-mobile">
                    <img src="@/assets/company2.png" alt="">
                </div>
                <div class="column box notification is-link is-light" style="font-family: Littera Plain">
                    <p class="title" style="font-family: RoadRadio;">сотрудничество</p>
                    <p class="subtitle" style="font-family: Littera Plain;">Каким оно должно быть</p>
                    <div class="tags " style="font-family: RoadRadio;">
                        <div class="tag is-primary">Надежность</div>
                        <div class="tag is-primary">Качество</div>
                        <div class="tag is-primary">Стабильность</div>
                        <div class="tag is-primary">Лояльность</div>
                    </div>
                    <div class="content">
                        Мы рады предложить вам возможность сотрудничества с нашей компанией в качестве интернет-провайдера.
                        Мы
                        осознаем важность надежного и стабильного интернет-подключения для успешного функционирования вашего
                        бизнеса и готовы предложить вам оптимальные решения, которые помогут удовлетворить все ваши
                        потребности.
                        <br>
                        <br>
                        Наша компания обладает превосходным опытом в предоставлении высокоскоростного и стабильного
                        интернет-соединения для различных типов предприятий. Мы понимаем, насколько важно для вас, чтобы
                        ваши
                        клиенты и сотрудники имели быстрый доступ в сеть, поэтому мы готовы предложить вам широкий спектр
                        услуг,
                        которые подойдут именно под ваши требования.
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ScrollButton @scrollTop="scrollTop" />

    <UTFooter />
</template>

<script>
import BaseCarousel from '@/components/Base/BaseCarousel.vue';
import ScrollButton from '@/components/Base/ScrollButton.vue';
import BaseNavbarBg from '@/components/Base/BaseNavbarBg.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import ServicesNavbarCards from '@/components/services/ServicesNavbarCards.vue';
import ServicesTopBenefits from '@/components/services/ServicesTopBenefits.vue'
export default {
    name: "ServicesView",
    components: {
        BaseNavbarBg,
        UTFooter,
        ServicesTopBenefits,
        ServicesNavbarCards,
        BaseCarousel,
        ScrollButton
    },
    data() {
        return {
            isShowBurgerMenu: false,
        }
    },
    methods: {
        scrollTop() {
            let viewPort = window.scrollY
            if (viewPort > 500) {
                window.scrollTo(0, 0);
                this.isHidden = false
            }

        },
    },
    created() {
        window.scrollTo(0, 0);
    }
}

</script>

<style scoped>
@import '@creativebulma/bulma-tooltip';

.ut-title {
    font-size: 30px;
    font-weight: 600;

    letter-spacing: 0.5px;
}
.title {
    font-size: 25px;
}
.hero {
    background: url("@/assets/services-bg.jpg");
    font-family: RoadRadio;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
}
@media(max-width: 768px) {
    .ut-title {
        font-size: 25px;
    }
    .title {
        font-size: 20px;
    }
    .subtitle {
        font-size: 15px;
    }
    .button {
        font-size: 15px;
    }
}
@media(max-width: 320px) {
    .ut-title {
        font-size: 20px;
    }
    .title {
        font-size: 18px;
    }
    .subtitle {
        font-size: 14px;
    }
    .button {
        font-size: 14px;
    }
}
</style>