<template>
    <div class="container">
        <p class="title has-text-white is-hidden-mobile	">
            <slot name="carousel-title"></slot>
        </p>
        <Carousel :breakpoints="breakpoints" :wrap-around="true">
            <slide class="p-5" :key="1">
                <div class="stock-card ut-overlay  p-5">
                    <div class="stock-card-header">
                        <p class="title has-text-white">
                            <slot name="carousel-first-description-title"></slot>
                        </p>
                    </div>
                    <div class="stock-card-body" style="font-family: Littera Plain;">
                        <slot name="carousel-first-description-subtitle"></slot>

                    </div>
                </div>

            </slide>
            <slide class="p-5" :key="2">
                <div class="stock-card ut-overlay p-5">
                    <div class="stock-card-header">
                        <p class="title has-text-white">
                            <slot name="carousel-second-description-title"></slot>

                        </p>
                    </div>
                    <div class="stock-card-body" style="font-family: Littera Plain;">
                        <slot name="carousel-second-description-subtitle"></slot>

                    </div>
                </div>
            </slide>
            <template #addons>
                <navigation class="has-text-white" />
            </template>
        </Carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
export default {
    name: "BaseCarousel",
    components: {
        Carousel,
        Navigation,
        Slide
    },
    data() {
        return {
            breakpoints: {
                // 700px and up
                300: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                400: {
                    itemsToShow: 1,
                    snapAlign: 'center'
                },
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
            },

        }
    }
}
</script>

<style scoped>
.stock-card {
    min-width: 500px;
    max-width: 800px;

    min-height: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white
}

.ut-overlay {
    background: linear-gradient(179.27deg, #d9d7e073 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
    border-radius: 15px;
}

@media(max-width: 768px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        min-width: 350px;
        max-width: 350px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }

    .title {
        font-size: 25px;
        font-weight: 900;
    }

    .stock-card {
        min-width: 300px;
        max-width: 300px;
        font-size: 15px;

    }
}

@media(max-width: 375px) {
    .stock-card {
        min-width: 270px;
        max-width: 270px;
        font-size: 15px;
        max-height: 50px;
    }
}

@media(max-width: 320px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        min-width: 350px;
        max-width: 350px;

        font-size: 15px;

        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

    }

    .title {
        font-size: 15px;
        font-weight: 900;
    }

    .stock-card {
        min-width: 230px;
        max-width: 230px;
        font-size: 15px;
        max-height: 50px;

    }
}
</style>