<template>
    <div class="overlay"></div>
    <div class="hero-head">
        <nav class="navbar is-hidden-tablet" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <router-link :to="{ name: 'home' }">
                    <img src="@/assets/logo.svg" width="100">
                </router-link>

                <a role="button" class="navbar-burger has-text-white" @click="showBurgerMenu"
                    :class="{ 'is-active': isShowBurgerMenu }" aria-label="menu" aria-expanded="false"
                    data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isShowBurgerMenu }" style="font-size: 16px;">
                <div class="navbar-start">
                    <a class="navbar-item" href="#contacts">Контакты</a>
                    <router-link :to="{ name: 'partners' }" class="navbar-item">Партнеры</router-link>
                    <router-link :to="{ name: 'tarrifs' }" class="navbar-item">Тарифы</router-link>
                    <router-link :to="{ name: 'coverage-zone' }" class="navbar-item">Зона покрытия</router-link>
                    <router-link :to="{ name: 'services' }" class="navbar-item">Услуги</router-link>
                    <a href="https://my.urbantelecom.uz" class="navbar-item">Личный кабинет</a>
                </div>

            </div>
        </nav>

        <nav class="level is-relative is-hidden-mobile" style="z-index: 10;">
            <p class="level-item has-text-centered">
                <a class="ut-link" href="#contacts">Контакты</a>
            </p>
            <p class="level-item has-text-centered">
                <router-link :to="{ name: 'partners' }" class="ut-link">Партнеры</router-link>
            </p>
            <p class="level-item has-text-centered">
                <router-link :to="{ name: 'services' }" class="ut-link">Услуги</router-link>
            </p>
            <p class="level-item has-text-centered">
                <router-link :to="{ name: 'home' }">
                    <img src="@/assets/logo.svg" alt="" style="height: 100px;">
                </router-link>
            </p>
            <p class="level-item has-text-centered">
                <router-link :to="{ name: 'tarrifs' }" class="ut-link">Тарифы</router-link>
            </p>
            <p class="level-item has-text-centered">
                <router-link :to="{ name: 'coverage-zone' }" class="ut-link">Зона покрытия</router-link>
            </p>
            <p class="level-item has-text-centered">
                <a href="https://my.urbantelecom.uz" class="ut-link" data-tooltip="Личный кабинет">ЛК</a>
            </p>
        </nav>
    </div>

    <div class="hero-body" style="z-index:10">
        <div class="container">
            <slot name="title"></slot>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import ServicesTopBenefits from '@/components/services/ServicesTopBenefits.vue';
import ServicesNavbarCards from '@/components/services/ServicesNavbarCards.vue';
export default {
    name: "BaseNavbarBg",
    components: {
        ServicesTopBenefits,
        ServicesNavbarCards
    },
    data() {
        return {
            isShowBurgerMenu: false,
        }
    },
    methods: {
        showBurgerMenu() {
            if(this.isShowBurgerMenu === false) {
                this.isShowBurgerMenu = true
            }
            else {
                this.isShowBurgerMenu = false
            }
        }
    }
}
</script>

<style scoped>
.overlay {
    background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}


.ut-link {
    color: white;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;

}

.ut-link:after {
    display: block;
    content: '';
    border-bottom: solid 3px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;


}

.ut-link:hover {
    color: white;
}

.ut-link:hover:after {
    transform: scaleX(1);
}

.ut-link:after {
    transform-origin: 0% 50%;
}


@media(max-width: 425px) {
    .navbar-item {
        font-size: 14px;
    }
}

@media(max-width: 375px) {
    .navbar-item {
        font-size: 14px;
    }
}

@media(max-width: 320px) {
    .navbar-item {
        font-size: 14px;
    }
}
</style>