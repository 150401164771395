import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import {
	faNewspaper,
	faGears,
	faFire,
	faEthernet,
	faQuestion,
	faGlobe,
	faPhone,
	faUser,
	faSun,
	faMoon,
	faMoneyBill1,
	faBuilding,
	faNetworkWired,
	faArrowDown,
	faFaceFlushed,
	faFile,
	faPassport,
	faFileExcel,
	faMapLocationDot,
	faInfinity,
	faGaugeHigh,
	faAngleUp,
	faLocationDot,
	faStar,
	faServer,
	faChartPie,
	faMountainCity,
	

} from '@fortawesome/free-solid-svg-icons'
import {
	library
} from '@fortawesome/fontawesome-svg-core'

library.add(

	faNewspaper,
	faGears,
	faFire,
	faEthernet,
	faQuestion,
	faGlobe,
	faPhone,
	faUser,
	faSun,
	faMoon,
	faMoneyBill1,
	faBuilding,
	faNetworkWired,
	faArrowDown,
	faFaceFlushed,
	faFile,
	faPassport,
	faFileExcel,
	faMapLocationDot,
	faInfinity,
	faGaugeHigh,
	faAngleUp,
	faLocationDot,
	faStar,
	faServer,
	faChartPie,
	faMountainCity,

)

axios.defaults.baseURL = 'https://api.urbantelecom.uz/'


createApp(App).use(router).use(store).mount('#app')