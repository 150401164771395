<template>
    <section class="hero is-medium is-relative box">
        <BaseNavbarBg>
            <template v-slot:title>
                <p class="ut-title has-text-white has-text-centered">
                    Аренда сети
                </p>
            </template>
            <template v-slot:content>
                <ServicesNavbarCards>
                    <template v-slot:first-title>
                        Безопасность
                    </template>
                    <template v-slot:first-subtitle>
                        Резервирование
                    </template>
                    <template v-slot:second-title>
                        Скорость
                    </template>
                    <template v-slot:second-subtitle>
                        Без ограничений
                    </template>
                    <template v-slot:third-title>
                        Легально
                    </template>
                    <template v-slot:third-subtitle>
                        Все лицензировано
                    </template>
                </ServicesNavbarCards>

                <BaseCarousel>
                    <template v-slot:carousel-title>
                        Акции и скидки
                    </template>
                    <template v-slot:carousel-first-description-title>
                        Скидки до 20% на все тарифные планы
                    </template>
                    <template v-slot:carousel-first-description-subtitle>
                        При единовременной оплате
                    </template>
                    <template v-slot:carousel-second-description-title>
                        Тест-драйв до 5 дней
                    </template>
                    <template v-slot:carousel-second-description-subtitle>
                        У вас есть целых 5 дней, чтобы оценить надежность нашего соединения, высокую и стабильную
                        скорость
                    </template>
                </BaseCarousel>

            </template>
        </BaseNavbarBg>
    </section>

    <section class="section">
        <div class="container">
            <p class="title" style="font-family: RoadRadio;">Преимущество для операторов связи</p>
            <div class="columns" style="font-family: Littera Plain;">
                <div class="column">
                    <div class="card notification has-background-white-bis" style="min-height: 200px;">
                        <div class="card-content">
                            <div
                                class="content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                                <div class="mb-3">
                                    <font-awesome-icon icon="network-wired" size="2xl" style="color:#0a0e7b;" />
                                </div>
                                <div>
                                    Наличие собственных оптических каналов до основных точек обмена трафика
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column">
                    <div class="card notification has-background-white-bis" style="min-height: 200px;">
                        <div class="card-content">
                            <div
                                class="content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                                <div class="mb-3">
                                    <font-awesome-icon icon="server" size="2xl" style="color:#0a0e7b;" />
                                </div>
                                <div>
                                    Качественное оборудование и резервирование каналов связи
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="columns" style="font-family: Littera Plain;">
                <div class="column">
                    <div class="card notification has-background-white-bis" style="min-height: 200px;">
                        <div class="card-content ">
                            <div
                                class="content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                                <div class="mb-3">
                                    <font-awesome-icon icon="chart-pie" size="2xl" style="color:#0a0e7b;" />
                                </div>
                                <div>
                                    Автомониторинг сети 24/7 и оперативное устранение инцидентов
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column">
                    <div class="card notification has-background-white-bis" style="min-height: 200px;">
                        <div class="card-content ">
                            <div
                                class="content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                                <div class="mb-3">
                                    <font-awesome-icon icon="gauge-high" size="2xl" style="color:#0a0e7b;" />
                                </div>
                                <div>
                                    Доступ в Интернет, на базе собственной сети с высокой скоростью
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <p class="title" style="font-family: RoadRadio;">Преимущества аренды сети для бизнеса</p>
            <div class="columns" style="font-family: Littera Plain;">
                <div class="column">
                    <article class="message is-info">
                        <div class="message-header">
                            <p style="font-family: RoadRadio;">Безопасность</p>
                        </div>
                        <div class="message-body p-2">
                            Защитите корпоративные данные. Вся информация передается по собственной защищенной сети
                            Urban Telecom. Перехватить данные из локальной сети организованной через VPN почти
                            невозможно, в отличие от обычной пересылки по интернету.
                        </div>
                    </article>
                </div>
                <div class="divider is-vertical is-primary">
                    <font-awesome-icon icon="star" style="color: #1dd396;" />
                </div>
                <div class="column">
                    <article class="message is-info">
                        <div class="message-header">
                            <p style="font-family: RoadRadio;">Высокая скорость</p>
                        </div>
                        <div class="message-body p-2">
                            Передавайте большие объемы информации на неограниченной скорости, без задержек и потери
                            качества за счет маршрутизация трафика по кратчайшему пути! И даже без наличия
                            интернета! Стабильная пропускная способность гарантируется тем, что канал используют
                            только сотрудники вашей компании.
                        </div>
                    </article>

                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Заполните форму</p>
                    <p class="subtitle is-hidden-mobile" style="font-family: RoadRadio;">Мы свяжемся с вами</p>
                    <div class="content" style="font-family: Littera Plain;">
                        Найдем оптимальное решение для вашего бизнеса.<br>
                        Ваша улыбка - наш успех!
                    </div>
                </div>
                <div class="column box">
                    <ServicesForm @clientRequest="clientRequest" />
                </div>
            </div>
        </div>
    </section>
    <ScrollButton @scrollTop="scrollTop" />
    <section>
        <UTFooter />
    </section>
</template>

<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import ScrollButton from '@/components/Base/ScrollButton.vue';
import ServicesNavbarCards from '@/components/services/ServicesNavbarCards.vue'
import BaseNavbarBg from '@/components/Base/BaseNavbarBg.vue';
import BaseCarousel from '@/components/Base/BaseCarousel.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import axios from 'axios';
import ServicesForm from '@/components/Base/ServicesForm.vue';
export default {
    name: "ServicesIpTransport",
    components: {
        BaseNavbarBg,
        UTFooter,
        ServicesNavbarCards,
        BaseCarousel,
        FontAwesomeIcon,
        ScrollButton,
        ServicesForm
    },
    methods: {
        scrollTop() {
            let viewPort = window.scrollY
            if (viewPort > 500) {
                window.scrollTo(0, 0);
                this.isHidden = false
            }

        },
        clientRequest(formData) {
            formData.service = "IP-транспорт"
            axios
                .post('/api/v1/services-message/', formData)
                .then(response => {
                    console.log(response);
                })
        }
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>


<style scoped>
@import "~@creativebulma/bulma-divider";

.ut-title {
    font-size: 30px;
    font-weight: 600;

    letter-spacing: 0.5px;
}

.hero {
    background: url('@/assets/1.jpg') center no-repeat;
    object-fit: cover;
    font-family: RoadRadio;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
}

.card {
    min-height: 300px;
}

.message {
    min-height: 250px;
    max-height: 250px;
}

@media(max-width: 768px) {
    .ut-title {
        font-size: 25px;
    }

    .title {
        font-size: 20px;
    }

    .subtitle {
        font-size: 15px;
    }

    .button {
        font-size: 15px;
    }
}

@media(max-width: 375px) {
    .message-body {
        font-size: 15px;
    }
}

@media(max-width: 320px) {
    .title {
        font-size: 18px;
    }

    .subtitle {
        font-size: 14px;
    }

    .button {
        font-size: 14px;
    }

    .message-body {
        font-size: 14px;
    }
}
</style>