<template>
	<div class="columns">
		<div class="container">
			<Carousel :breakpoints="breakpoints">
			<slide v-for="tarrif in popularTarrifs" :key="tarrif.id" class="p-5">

				<div class="ut-tarrif-card is-relative">
					<div class="ut-overlay is-overlay"></div>
					<div class="ut-tarrif-card-header is-relative mb-5">
						<div class="has-text-white ut-tarrif-card-header">
							{{ tarrif.tarrif_name }}
						</div>
					</div>
					<div class="ut-tariif-card-tags is-relative tags mb-5">
						<div class="tag is-primary">FTTX</div>
						<div class="tag is-primary">UNLIM</div>
						<div class="tag is-primary">TAS-IX</div>
					</div>
					<div class="is-flex is-relative is-align-items-flex-start mb-5">
						<div class="mr-3">
							<font-awesome-icon icon="sun" size="xl" />
						</div>
						<div class="is-flex is-flex-direction-column is-align-items-flex-start">
							<span class="ut-tariif-card-inner-desc-top">
								{{ tarrif.tarrif_day_speed }} Мб/с
							</span>
							<span class="ut-tariif-card-inner-desc-bottom">Скорость днем</span>
						</div>
					</div>
					<div class="is-flex is-align-items-flex-start is-relative mb-5">
						<div class="mr-3">
							<font-awesome-icon icon="moon" size="xl" />
						</div>
						<div class="is-flex is-flex-direction-column is-align-items-flex-start">
							<span class="ut-tariif-card-inner-desc-top">
								{{ tarrif.tarrif_night_speed }} Мб/с
							</span>
							<span class="ut-tariif-card-inner-desc-bottom">Скорость ночью</span>
						</div>
					</div>
					<div class="is-flex is-align-items-flex-start is-relative mb-5">
						<div class="mr-3">
							<font-awesome-icon icon="money-bill-1" size="xl" />
						</div>
						<div class="is-flex is-flex-direction-column">
							<span class="ut-tariif-card-inner-desc-top">
								Договорная
							</span>
						</div>
					</div>
					<div>
						<button class="ut-tarrif-card-button" @click="showConnectModal">Подключить</button>
					</div>
				</div>
			</slide>
			<template #addons>
				<navigation class="has-text-white is-hidden-mobile"/>
			</template>

		</Carousel>
		</div>

		<ConnectModal @clientRequest="clientRequest" @showConnectModal="showConnectModal" :class="{'is-active': isShowConnectModal}"/>
	</div>
</template>
<script>
import ConnectModal from '@/components/Home/ConnectModal.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

export default {
	name: "PriceTable",
	props: {
		popularTarrifs: Array
	},
	data() {
		return {
			isAddClass: false,
			isShowConnectModal: false,

			// breakpoints are mobile first
			// any settings not specified will fallback to the carousel settings
			breakpoints: {
				// 700px and up
				300: {
					itemsToShow: 1,
					snapAlign: 'start'
				},
				400: {
					itemsToShow: 1,
					snapAlign: 'start'
				},
				700: {
					itemsToShow: 3,
					snapAlign: 'start',
				},
				// 1024 and up
				1024: {
					itemsToShow: 3,
					snapAlign: 'start',
				},
			},
		}
	},
	components: {
		FontAwesomeIcon,
		Carousel,
		Slide,
		Navigation,
		ConnectModal
	},
	methods: {
		showConnectModal() {
			if(this.isShowConnectModal === false) {
				this.isShowConnectModal = true
			}
			else {
				this.isShowConnectModal = false
			}
		},
		addClass(event) {
			if (event.currentTarget.classList.contains("is-active")) {
				event.currentTarget.classList.remove("is-active")
			} else {
				event.currentTarget.classList.add("is-active")
			}

		},
		clientRequest(formData) {
			this.$emit('clientRequest', formData)
		}
	}
}
</script>
<style scoped>
.ut-tarrif-card {
	border-radius: 15px;
	font-family: "RoadRadio";
	color: white;
	margin: 10px;
	padding: 30px;

	min-height: 500px;
	min-width: 300px;
	max-width: 300px;
	
	transition: all 0.5s ease;

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	z-index: 99;

}

.ut-tarrif-card:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.ut-overlay {
	background: linear-gradient(179.27deg, #a9a1c480 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
	border-radius: 15px;
}

.ut-tariif-card-inner-desc-bottom {
	font-family: "Littera Plain";
	font-weight: 500;
	font-size: 15px;
	color: darkgrey;
}

.ut-tariif-card-inner-desc-top {
	font-size: 20px;
	line-height: 20px;

}

.ut-tarrif-card-button {
	border-radius: 15px;
	border: none;
	background: rgba(112, 43, 192, 0.1);
	padding: 15px;
	color: white;
	position: relative;
	font-family: "RoadRadio";
	font-size: 17px;
	letter-spacing: 1px;
	width: 100%;
	cursor: pointer;
	transition: all 0.5s ease;
}

.ut-tarrif-card-button:hover {
	background: rgba(52, 20, 99, 0.1);
	text-decoration: underline;

}

.tag {
	letter-spacing: 0.2px;
	font-weight: 900;
	font-size: 12px;
}

@media(max-width: 320px) {
	.ut-tarrif-card:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
	.ut-tarrif-card {
		min-width: 250px;
		max-width: 250px;

		padding: 10px;
	}

	.ut-tarrif-card-header {
		font-size: 20px;
	}

	.ut-tariif-card-inner-desc-top {
		font-size: 14px;
	}

	.ut-tariif-card-inner-desc-bottom {
		font-size: 14px;
	}

	.tag {
		font-size: 10px;
	}

	.ut-tarrif-card-button {
		font-size: 14px;
	}
}
</style>