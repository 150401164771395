<template>
    <div class="m-4">
        <BaseNavbar/>
    </div>
    <section class="section">
        <p class="title is-centred" style="font-family: RoadRadio;">Станьте частью нашей команды</p>
        <p class="subtitle" style="font-family: RoadRadio;">частью корабля</p>
        <div class="ut-vacancies container mt-5" style="border-radius: 12px;" >
                <img src="@/assets/manager-vacancies.jpg" class="is-hidden-mobile" alt="laptop" style="border-top-left-radius: 50px; border-bottom-left-radius: 50px; object-fit: cover;">
                <div class="ut-vacancies-description content" style="background-color: #b4e5f821; ">    
                    <div class="ut-vacancies-description-title">
                        <span>Менеджер по продажам</span>

                    </div>
                    <div class="tags">
                        <div class="tag is-primary">Опыт работаты от года</div>
                        <div class="tag is-primary">Официальное трудоустройство</div>
                    </div>
                    <p>Мы формируем международную команду профессионалов которые готовы расти и развиваться вместе с нашей Компанией, в которой каждый сотрудник по-настоящему важен!
                    Наши главные ценности: честность - открытость - активность - ответственность - клиентоориентированность.
                    </p>

                    <p class="subtitle">Обязанности</p>
                    <ul>
                        <li>Привлечение новых корпоративных клиентов для продажи телекоммуникационных и сопутствующих услуг;</li>
                        <li>Составление коммерческих предложений;</li>
                        <li>Проведение встреч, презентаций для клиентов B2B;</li>
                        <li>Ведение полного сопровождения клиента от звонка до заключения договора;</li>

                    </ul>

                    <p class="subtitle">Требования</p>
                    <ul>
                        <li>Наличие отличных презентационных и коммуникативных навыков;</li>
                        <li>Готовность постоянно профессионально развиваться;</li>
                        <li>Умение работать в команде и  отвечать за свой результат;</li>
                        <li>Знание русского и узбекского языка;</li>
                        <li>Опыт работы в продажах B2B не обязателен, но будет преимуществом;</li>

                    </ul>

                    <p class="subtitle">Что мы предлагаем взамен:</p>
                    <ul>
                        <li>Возможность реализовать свой потенциал и идеи, карьерный рост;</li>
                        <li>Поощрение лучших результатов (выполнение плана продаж, полезные идеи и инициативы);</li>
                        <li>Фиксированная зарплата + бонус при выполнении ежемесячного плана;</li>
                        <li>График работы: 5/2, с 9:00 до 18:00 с перерывом на обед;</li>
                        <li>Официальное трудоустройство согласно ТК РУз + все социальные льготы и гарантии, предусмотренные законодательством;</li>
                        <li>Оплата телефонной связи;
                            сли Вы хотите и умеете учиться, инициативны и ответственны, вовлечены и коммуникативны, мы будем рады видеть Вас в команде!
                        </li>
            
                    </ul>

                </div>
            </div>
    </section>
    <UTFooter/>
</template>

<script>
    import BaseNavbar from '@/components/Base/BaseNavbar.vue';
    import UTFooter from '@/components/Base/UTFooter.vue';
    export default {
        name: "VacanciesVIew",
        components: {
            BaseNavbar,
            UTFooter
        },
        created() {
			window.scrollTo(0, 0);
		}
    }
</script>

<style scoped>
    .ut-vacancies {
        display: flex;
    }

    .ut-vacancies-description {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        
        padding: 25px;
        font-family: "Littera Plain";
    }

    .ut-vacancies-description-title {
        font-family: "RoadRadio";
        font-size: 30px;
        
    }

    .tag {
        font-family: "RoadRadio";
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.5px;
    }

    @media(max-width: 320px) {
        .title {
            font-size: 14px;
        }

        .subtitle {
            font-size: 14px;
        }

        .ut-vacancies-description-title {
            font-size: 14px;
        }

        .ut-vacancies-description {
            font-size: 14px;
            padding: 15px
        }
        
        .tag {
            font-size: 10px;
        }
    }

</style>