<template>
    <nav class="level is-relative mt-1 is-hidden-mobile" style="z-index: 10; font-family: RoadRadio; letter-spacing: 1px;">
        <p class="level-item has-text-centered">
            <a class="ut-base-navbar-link" href="#contacts">Контакты</a>
        </p>
        <p class="level-item has-text-centered">
            <router-link :to="{ name: 'partners' }" class="ut-base-navbar-link">Партнеры</router-link>
        </p>
        <p class="level-item has-text-centered">
            <router-link :to="{ name: 'services' }" class="ut-base-navbar-link">Услуги</router-link>
        </p>
        
        <p class="level-item has-text-centered">

            <router-link :to="{ name: 'home' }">
                <img src="@/assets/logo_d.svg" alt="" style="height: 100px;">
            </router-link>

        </p>
        <p class="level-item has-text-centered">
            <router-link :to="{ name: 'tarrifs' }" class="ut-base-navbar-link">Тарифы</router-link>
        </p>
        <p class="level-item has-text-centered">
            <router-link :to="{ name: 'coverage-zone' }" class="ut-base-navbar-link">Зона покрытия</router-link>
        </p>
        <p class="level-item has-text-centered">
            <a href="https://my.urbantelecom.uz" class="ut-base-navbar-link">ЛК</a>
        </p>
    </nav>
    <nav class="navbar is-hidden-tablet" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <router-link :to="{ name: 'home' }">
                <img src="@/assets/logo_d.svg" width="100">
            </router-link>

            <a role="button" class="navbar-burger" @click="showBurgerMenu"
                :class="{ 'is-active': isShowBurgerMenu }" aria-label="menu" aria-expanded="false"
                data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isShowBurgerMenu }" style="font-family: RoadRadio;">
            <div class="navbar-start">
                <a class="navbar-item" href="#contacts">Контакты</a>
                <router-link :to="{ name: 'partners' }" class="navbar-item">Партнеры</router-link>
                <router-link :to="{ name: 'tarrifs' }" class="navbar-item">Тарифы</router-link>
                <router-link :to="{ name: 'coverage-zone' }" class="navbar-item">Зона покрытия</router-link>
                <router-link :to="{ name: 'services' }" class="navbar-item">Услуги</router-link>
                <a href="https://my.urbantelecom.uz" class="navbar-item">Личный кабинет</a>
            </div>

        </div>
    </nav>
</template>

<script>
export default {
    name: "BaseNavbar",
    data() {
        return {
            isShowBurgerMenu: false
        }
    },
    methods: {
        showBurgerMenu() {
            if(this.isShowBurgerMenu === false) {
                this.isShowBurgerMenu = true
            }
            else {
                this.isShowBurgerMenu = false
            }
        }
    }
}
</script>

<style>
.ut-base-navbar-link {
    color: #271856;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;

}

.ut-base-navbar-link:after {
    display: block;
    content: '';
    border-bottom: solid 3px #271856;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;


}

.ut-base-navbar-link:hover {
    color: #271856;
}

.ut-base-navbar-link:hover:after {
    transform: scaleX(1);
}

.ut-base-navbar-link:after {


    transform-origin: 0% 50%;
}

@media(max-width: 425px) {
    .navbar-item {
        font-size: 14px;
    }
}

@media(max-width: 375px) {
    .navbar-item {
        font-size: 14px;
    }
}

@media(max-width: 320px) {
    .navbar-item {
        font-size: 14px;
    }
}
</style>