<template>
    <section class="hero is-medium is-relative box">
        <BaseNavbarBg>
            <template v-slot:title>
                <p class="title has-text-white has-text-centered">
                    Строительство - Проектирование
                </p>

            </template>
            <template v-slot:content>
                <ServicesNavbarCards>
                    <template v-slot:first-title>
                        Безопасность
                    </template>
                    <template v-slot:first-subtitle>
                        Надежность
                    </template>
                    <template v-slot:second-title>
                        Скорость
                    </template>
                    <template v-slot:second-subtitle>
                        Все в дедлайн
                    </template>
                    <template v-slot:third-title>
                        Консультация
                    </template>
                    <template v-slot:third-subtitle>
                        Мы всегда рады помочь
                    </template>
                </ServicesNavbarCards>

                <BaseCarousel>
                    <template v-slot:carousel-title>
                        Акции и скидки
                    </template>
                    <template v-slot:carousel-first-description-title>
                        Структурированная кабельная система
                    </template>
                    <template v-slot:carousel-first-description-subtitle>
                        При единовременной оплате скидка 10%
                    </template>
                    <template v-slot:carousel-second-description-title>
                        Пуско-наладка оборудования
                    </template>
                    <template v-slot:carousel-second-description-subtitle>
                        Качественно и быстров в срок
                    </template>
                </BaseCarousel>

            </template>
        </BaseNavbarBg>
    </section>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column" style="font-family: RoadRadio;">
                    <p class="title">
                        Услуги проектирования и строительства сетей
                    </p>
                    <p class="subtitle is-hidden-mobile" style="font-family: Littera Plain;">
                        Воплощайте в жизнь свои амбиции с нашей командой профессионалов по проектированию и строительству
                        сетей!
                    </p>

                </div>

                <div class="column">
                    <div class="content notification is-info is-light box" style="font-family: Littera Plain;">
                        Мы предлагаем широкий спектр услуг, связанных с проектированием и строительством сетей различных
                        масштабов: от небольших офисных и домашних сетей до крупных городских и корпоративных инфраструктур.
                        Наши опытные инженеры и архитекторы разработают индивидуальное решение, полностью соответствующее
                        вашим потребностям и требованиям. Они учтут все технические и эргономические аспекты, чтобы
                        обеспечить стабильную работу и высокую скорость передачи данных.
                    </div>
                </div>
            </div>
            <div class="divider">
                <font-awesome-icon icon="star" style="color: #1d20d3;" />
            </div>
            <div class="columns">
                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Консультационные услуги</p>
                    <p class="subtitle is-hidden-mobile" style="font-family: Littera Plain;">
                        По построению сетей связи и введению их в эксплуатацию
                    </p>
                </div>
                <div class="column">
                    <div class="content notification is-info is-light box" style="font-family: Littera Plain;">
                        Мы работаем с самыми последними технологиями и используем передовое оборудование, чтобы
                        гарантировать высокий уровень качества нашей работы. Наша команда постоянно обновляется и
                        совершенствуется, чтобы быть в курсе последних тенденций и новейших разработок в области сетевых
                        технологий.
                    </div>
                </div>

            </div>
            <div class="divider">
                <font-awesome-icon icon="star" style="color: #1d20d3;" />
            </div>
            <div class="columns">

                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Разработка и согласование проекта</p>
                    <p class="subtitle is-hidden-mobile" style="font-family: Littera Plain;">
                        Вы всегда будете в курсе событий
                    </p>
                </div>
                <div class="column">
                    <div class="content notification is-info is-light box" style="font-family: Littera Plain;">
                        Мы гарантируем своевременное выполнение проекта и эффективное использование всех ресурсов. Мы
                        понимаем, что каждая минута задержки или неполадка может привести к серьезным проблемам и потере
                        бизнеса для вас. Поэтому мы превыше всего ценим ваше время и деньги.
                    </div>
                </div>
            </div>
            <div class="divider">
                <font-awesome-icon icon="star" style="color: #1d20d3;" />
            </div>
            <p class="title has-text-centered" style="font-family: RoadRadio;">Услуги</p>

            <div class="columns">
                <div class="column">
                    <article class="message is-info">
                        <div class="message-header">
                            <p style="font-family: RoadRadio;">Все включено</p>
                        </div>
                        <div class="message-body p-2" style="font-family: Littera Plain">
                            Поставка, монтаж и пусконаладка телекоммуникационного оборудования.
                            с ведущими производителями современного телекоммуникационного оборудования. Мы осуществляем
                            полный
                            комплекс работ по поставке, монтажу и пусконаладке сетей связи в соответствии с разработанным
                            техническо-экономическим обоснованием проекта.
                        </div>
                    </article>

                </div>
                <div class="column">
                    <article class="message is-info">
                        <div class="message-header">
                            <p style="font-family: RoadRadio;">ВОЛС</p>
                        </div>
                        <div class="message-body p-2" style="font-family: Littera Plain">
                            Волоконно-оптические линии связи (ВОЛС) — одна из самых фундаментальных технологий в области
                            телекоммуникаций. Возможности Urban Telecom позволяют строить сети оптоволоконной связи во всех
                            частях города обеспечивая высокую скорость передачи данных.
                        </div>
                    </article>
                </div>
                <div class="column">
                    <article class="message is-info">
                        <div class="message-header">
                            <p style="font-family: RoadRadio;">СКС</p>
                        </div>
                        <div class="message-body p-2" style="font-family: Littera Plain">
                            Структурированная кабельная система ─ это интеллектуальная инфраструктура вашего здания.
                            Использование унифицированных разъемов делает ее совместимой с любым оборудованием:
                            компьютерами,
                            телефонами, датчиками. Качественно спроектированная и построенная структурированная кабельная
                            система, соответствующая современным стандартам качества, способна прослужить несколько
                            десятилетий.
                        </div>
                    </article>
                </div>
            </div>

            <div class="divider">
                <font-awesome-icon icon="star" style="color: #1d20d3;" />
            </div>
            <div class="columns">

                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Свяжитесь с нами</p>
                    <p class="subtitle is-hidden-mobile" style="font-family: Littera Plain;">
                        Начните строительство сетевой инфраструктуры
                    </p>
                </div>
                <div class="column box">
                    <ServicesForm @clientRequest="clientRequest" />
                </div>
            </div>

        </div>
    </section>
    <ScrollButton @scrollTop="scrollTop" />
    <section>
        <UTFooter />
    </section>
</template>

<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import ScrollButton from '@/components/Base/ScrollButton.vue';
import ServicesNavbarCards from '@/components/services/ServicesNavbarCards.vue'
import BaseNavbarBg from '@/components/Base/BaseNavbarBg.vue';
import BaseCarousel from '@/components/Base/BaseCarousel.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import ServicesForm from '@/components/Base/ServicesForm.vue';
import axios from 'axios';
export default {
    name: 'ServicesFiberDesignView',
    components: {
        BaseNavbarBg,
        BaseCarousel,
        UTFooter,
        FontAwesomeIcon,
        ServicesNavbarCards,
        ScrollButton,
        ServicesForm
    },
    methods: {
        scrollTop() {
            let viewPort = window.scrollY
            if (viewPort > 500) {
                window.scrollTo(0, 0);
                this.isHidden = false
            }

        },
        clientRequest(formData) {
            formData.service = "Строительство"
            axios
                .post('/api/v1/services-message/', formData)
                .then(response => {
                    console.log(response);
                })
        }
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
@import "~@creativebulma/bulma-divider";

.ut-title {
    font-size: 30px;
    font-weight: 600;

    letter-spacing: 0.5px;
}

.hero {
    background: url('@/assets/services-fiber-design-bg.jpg') center;
    font-family: RoadRadio;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
}

.message {
    min-height: 300px;
    max-height: 300px;
}

@media(max-width: 768px) {
    .ut-title {
        font-size: 25px;
    }

    .title {
        font-size: 20px;
    }

    .subtitle {
        font-size: 15px;
    }

    .button {
        font-size: 15px;
    }
}

@media(max-width: 375px) {
    .message-body {
        font-size: 15px
    }

    .content {
        font-size: 15px;
    }
}

@media(max-width: 320px) {
    .title {
        font-size: 18px;
    }

    .subtitle {
        font-size: 14px;
    }

    .button {
        font-size: 14px;
    }

    .content {
        font-size: 14px;
    }

    .message-body {
        font-size: 14px;
    }
}
</style>