<template>
    <div class="columns">
        <div class="column">
            <div class="is-flex">
                <img src="@/assets/icon1.svg" alt="" class="mr-3">
                <span class="ut-services-top-benefit-icon-title">Стабильность<br>надежность</span>
            </div>
        </div>
        <div class="column">
            <div class="is-flex">
                <img src="@/assets/icon2.svg" alt="" class="mr-3">
                <span class="ut-services-top-benefit-icon-title">Оперативная <br> техподдержка</span>
            </div>
        </div>
        <div class="column">
            <div class="is-flex">
                <img src="@/assets/icon3.svg" alt="" class="mr-2">
                <div class="ut-services-top-benefit-icon-title">Быстрая <br>настройка</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServicesTopBenefits"
}
</script>

<style scoped>
.ut-services-top-benefit-icon-title {
    font-family: "RoadRadio";
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.5px;
    line-height: 25px;
}

.ut-services-top-benefit-icon-subtitle {
    font-weight: 600;
    font-size: 14px;
}
@media(max-width: 768px) {
    .ut-services-top-benefit-icon-title {

        font-size: 25px;

    }

    .ut-services-top-benefit-icon-subtitle {
        font-size: 10px;
    }
}
@media(max-width: 320px) {
    .ut-services-top-benefit-icon-title {

        font-size: 20px;

    }

    .ut-services-top-benefit-icon-subtitle {
        font-size: 10px;
    }
}
</style>
