<template>
    <section class="hero is-success is-fullheight is-relative">
        <div class="overlay"></div>
        <div class="hero-body ">
            <div class="container has-text-centered" style="z-index: 23;">
                <div>
                    <img src="@/assets/logo.svg" alt="">
                </div>
                <p class="title mt-2 is-3" style="font-family: RoadRadio;">
                    Мы скоро вас приятно удивим
                </p>
                <button @click="this.$router.push({ name: 'home' })" class="button is-medium is-link" style="font-family: RoadRadio; letter-spacing: 0.5px;" >Перейти на главную</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CRMVIew'
}
</script>


<style scoped>
.hero {
    background: url('@/assets/tarrif_bg.jpg') no-repeat;
    object-fit: cover;
}

.overlay {
    background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}
</style>