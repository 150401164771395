<template>
    <section class="hero is-medium is-relative has-text-white box">
        <BaseNavbarBg>
            <template v-slot:title>
                <p class="title has-text-white has-text-centered">Корпоративным клиентам</p>
            </template>
            <template v-slot:content>
                <PopularPriceTable @clientRequest="clientRequest" :popularTarrifs="allEntityTarris"/>
            </template>
        </BaseNavbarBg>
    </section>
    <section class="section mt-5 mb-5">
        <div class="container">
            <AllTarrifs @clientRequest="clientRequest" :individualTarrifs="allIndividualTarrifs" :entityTarrifs="allEntityTarris"/>
        </div>
    </section>

    <section class="section mt-5 mb-5">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <p class="title" style="font-family: RoadRadio;">Мы лояльны к клиентам</p>
                    <p class="subtitle" style="font-family: RoadRadio;">Сделаем для вас индивидуальный тариф</p>
                </div>

                <div class="column">
                    <ConnectForm @clientRequest="clientRequest" />
                </div>
            </div>
        </div>
    </section>
    <UTFooter />
</template>

<script>
import axios from 'axios';
import ConnectForm from '@/components/Base/ConnectForm.vue'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import BaseNavbarBg from '@/components/Base/BaseNavbarBg.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import PopularPriceTable from '@/components/Home/PopularPriceTable.vue';
import AllTarrifs from '@/components/Base/AllTarrifs.vue';
export default {
    name: "TarrifsView",
    components: {
        BaseNavbarBg,
        UTFooter,
        PopularPriceTable,
        AllTarrifs,
        FontAwesomeIcon,
        ConnectForm
    },
    data() {
        return {
            tarrifSpeed: 'Выберите скорость',
            isShowBurgerMenu: false,
            allIndividualTarrifs: [],
            allEntityTarris: [],
            popularTarrifs: []
        }
    },
    methods: {
        showBurgerMenu() {
            if (this.isShowBurgerMenu === false) {
                this.isShowBurgerMenu = true
            }
            else {
                this.isShowBurgerMenu = false
            }
        },
        clientRequest(formData) {
			axios
				.post('/api/v1/connect-message/', formData )
				.then(response => {
					console.log(response);
				})
		},
        getAllIndividualTarrifs() {
            axios
                .get('/api/v1/get-individual-tarrifs/')
                .then(response => {
                    this.allIndividualTarrifs = response.data
                })
        },
        getAllEntityTarrifs() {
            axios
                .get('/api/v1/get-entity-tarrifs/')
                .then(response => {
                    this.allEntityTarris = response.data
                })
        },
       
    },
    mounted() {
        this.getAllIndividualTarrifs()
        this.getAllEntityTarrifs()
    },
    created() {
        window.scrollTo(0, 0);
    }
}
</script>


<style scoped>
.hero {
    background-image: url('@/assets/tarrif_bg.jpg');
    font-family: RoadRadio;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
}

.ut-loyality {
    display: flex;
    justify-content: center;
}

.ut-loyality-form {
    background-color: #f4f4f4;
    padding: 30px;
    border-radius: 20px;
    min-height: 500px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-image: url('@/assets/night.jpg');
    background-image: cover;

}

.ut-overlay {
    background: linear-gradient(179.27deg, #271856 1.82%, rgba(40, 25, 87, 0.08) 187.85%);
    border-radius: 15px;
}

.ut-tarrif-card-button {
    border-radius: 15px;
    border: none;
    background: rgba(112, 43, 192, 0.1);
    padding: 15px;
    color: white;
    position: relative;
    font-family: "RoadRadio";
    font-size: 17px;
    letter-spacing: 1px;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease;
}

.ut-tarrif-card-button:hover {
    background: rgba(52, 20, 99, 0.1);
    text-decoration: underline;

}

@media(max-width: 320px) {
    .title {
        font-size: 20px;
        
    }

    .subtitle {
        font-size: 14px;
    }
}
</style>