<template>
	<section class="section">
		<BaseNavbar />
	</section>
	<section class="section">
		<div class="container">
			<p class="title" style="font-family: RoadRadio;">
				Необходимые документы
			</p>
			<p class="subtitle" style="font-family: RoadRadio;">
				для подключения
			</p>

			<div class="column is-3 box" style="font-family: Littera Plain;">
				<div class="mb-2">
					<font-awesome-icon icon="file" class="mr-2" size="lg" />
					<span style="font-size: 1.0625rem;">Гувохнома</span>
				</div>
				<div class="mb-2">
					<font-awesome-icon icon="passport" class="mr-2" size="lg" />
					<span style="font-size: 1.0625rem;">Паспорт директора</span>
				</div>
				<div class="mb-2">
					<font-awesome-icon icon="file-excel" class="mr-2" size="lg" />
					Кадастр или договор об аренде
				</div>
				<div class="mb-2">
					<font-awesome-icon icon="map-location-dot" class="mr-2" size="lg" />
					<span style="font-size: 1.0625rem;">Реквизиты компании</span>
				</div>
				<div class="mb-2">
					<font-awesome-icon icon="map-location-dot" class="mr-2" size="lg" />
					<span style="font-size: 1.0625rem;">Фактический адрес организации</span>
				</div>
				<div>
					<button class="button is-fullwidth is-info" style="font-family: RoadRadio;"
						@click="showConnectModal">Подключиться</button>
				</div>
			</div>
		</div>
		<ConnectModal :class="{ 'is-active': isShowConnectModal }" @showConnectModal="showConnectModal"
			@clientRequest="clientRequest" />
	</section>
	<UTFooter />
</template>

<script>
import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import BaseNavbar from '@/components/Base/BaseNavbar.vue';
import UTFooter from '@/components/Base/UTFooter.vue';
import ConnectModal from '@/components/Home/ConnectModal.vue';
import axios from 'axios';
export default {
	name: "DocumentsView",
	components: {
		ConnectModal,
		BaseNavbar,
		UTFooter,
		FontAwesomeIcon
	},
	data() {
		return {
			isShowConnectModal: false
		}
	},
	methods: {
		clientRequest(formData) {
			axios
				.post('/api/v1/connect-message/', formData)
				.then(response => {
				})
		},
		showConnectModal() {
			if (this.isShowConnectModal === false) {
				this.isShowConnectModal = true
			}
			else {
				this.isShowConnectModal = false
			}

		}
	},
	created() {
		window.scrollTo(0, 0);
	}
}
</script>